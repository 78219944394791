import React, { useEffect, useState } from "react";
import useEnterKeyPress from "../../../../utils/EnterCalculateBtn";
import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import { useSelector } from "react-redux";
// Data for feed types with all the values

function FeedCalculator() {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.feedAnalysis
			: language === "ar"
			? ar.services.feedAnalysis
			: fr.services.feedAnalysis;

	const feedTypes = [
		{
			id: 1,
			name: lang.yellow_corn,
			values: [
				0,
				0,
				0.0075,
				3.373,
				0.0019,
				0.00001,
				0.00012,
				0.00018,
				0.00015,
				0.00033,
				0.00024,
				0.00002,
				0.00033,
				0.00004,
				0.0019,
				0,
			],
		},
		{
			id: 2,
			name: lang.soya_bean_48,
			values: [
				0,
				0,
				0.046,
				2.458,
				0.00065,
				0.00031,
				0.00024,
				0.00058,
				0,
				0.00132,
				0.00262,
				0.00004,
				0.00205,
				0.00005,
				0.0006,
				0,
			],
		},
		{
			id: 3,
			name: lang.soya_bean_44,
			values: [
				0,
				0,
				0.042,
				2.24,
				0.0065,
				0.00025,
				0.0002,
				0.00065,
				0,
				0,
				0.00285,
				0.00004,
				0.00197,
				0.00002,
				0,
				0,
			],
		},
		{
			id: 4,
			name: lang.glotien_60,
			values: [
				0,
				0,
				0.06,
				3.74,
				0,
				0.00002,
				0.00018,
				0.0019,
				0,
				0.00243,
				0.001,
				0.00003,
				0.00045,
				0.00005,
				0.0018,
				0,
			],
		},
		{
			id: 5,
			name: lang.broiler_conc_48,
			values: [
				0,
				0,
				0.048,
				2.5,
				0.002,
				0.00608,
				0.003,
				0.0013,
				0,
				0.00219,
				0.0023,
				0.00066,
				0,
				0,
				0,
				0,
			],
		},
		{
			id: 6,
			name: lang.layers_conc_40,
			values: [
				0,
				0,
				0.04,
				2.22,
				0.004,
				0.00445,
				0.0024,
				0.00185,
				0,
				0.0026,
				0.00165,
				0.00105,
				0,
				0,
				0,
				0,
			],
		},
		{
			id: 7,
			name: lang.layers_conc_45,
			values: [
				0,
				0,
				0.045,
				2.67,
				0.0045,
				0.00542,
				0.00223,
				0.00326,
				0,
				0.00398,
				0.00394,
				0.0008,
				0,
				0,
				0,
				0,
			],
		},
		{
			id: 8,
			name: lang.layers_conc_50,
			values: [
				0,
				0,
				0.05,
				2.654,
				0.0045,
				0.00432,
				0.00255,
				0.00162,
				0,
				0.00338,
				0.00189,
				0.00126,
				0,
				0,
				0,
				0,
			],
		},
		{
			id: 9,
			name: lang.meat_bone_meal,
			values: [
				0,
				0,
				0.047,
				2.302,
				0.003,
				0.0105,
				0.00489,
				0.00055,
				0,
				0,
				0.00203,
				0,
				0,
				0,
				0,
				0,
			],
		},
		{
			id: 10,
			name: lang.bone_meal,
			values: [0, 0, 0, 0, 0, 0, 0.024, 0.012, 0, 0, 0, 0, 0.00046, 0, 0, 0],
		},
		{
			id: 11,
			name: lang.fish_meal,
			values: [
				0,
				0,
				0.061,
				2.6,
				0.001,
				0.007,
				0.0035,
				0.00165,
				0,
				0,
				0.0043,
				0.00097,
				0,
				0,
				0,
				0,
			],
		},
		{
			id: 12,
			name: lang.wheat_bran,
			values: [
				0,
				0,
				0.0148,
				1.3,
				0,
				0.00014,
				0.00038,
				0.00018,
				0,
				0,
				0.00057,
				0.00006,
				0.0012,
				0.00014,
				0.0021,
				0,
			],
		},
		{
			id: 13,
			name: lang.rice_bran,
			values: [
				0,
				0,
				0.0135,
				2.04,
				0.013,
				0.0001,
				0.00024,
				0.00017,
				0,
				0,
				0.0005,
				0.0001,
				0,
				0,
				0,
				0,
			],
		},
		{
			id: 14,
			name: lang.sodium_bicarbonate,
			values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.02738, 0, 0, 0, 0],
		},
		{
			id: 15,
			name: lang.salt,
			values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.03934, 0, 0.06066, 0, 0],
		},
		{
			id: 16,
			name: lang.calcium_carbonate,
			values: [0, 0, 0, 0, 0, 0.038, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		},
		{
			id: 17,
			name: lang.di_calcium_phosphate,
			values: [0, 0, 0, 0, 0, 0.022, 0.0185, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		},
		{
			id: 18,
			name: lang.mono_calcium_phosphate,
			values: [0, 0, 0, 0, 0, 0.016, 0.021, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		},
		{
			id: 19,
			name: lang.choline,
			values: [0, 0, 0, 0, 0, 0, 0, 0, 0.012, 0, 0, 0, 0, 0, 0, 0],
		},
		{
			id: 20,
			name: lang.DL_methionine,
			values: [0, 0, 0, 0, 0, 0, 0.00021, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		},
		{
			id: 21,
			name: lang.L_Lysine,
			values: [0, 0, 0, 0, 0, 0, 0.00012, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		},
		{
			id: 22,
			name: lang.soya_bean_oil,
			values: [0, 0, 0, 0, 0, 0, 0, 0, 0.011, 0.0011, 0, 0, 0, 0, 0, 0],
		},
		{
			id: 23,
			name: lang.poultry_fat,
			values: [0, 0, 0, 0, 0, 0, 0, 0, 0.008, 0.0012, 0, 0, 0, 0, 0, 0],
		},
		{
			id: 24,
			name: lang.perimix,
			values: [
				0,
				0,
				0.03,
				2.5,
				0.001,
				0.006,
				0.003,
				0.002,
				0,
				0.003,
				0.0025,
				0.0005,
				0.0005,
				0.0002,
				0.0005,
				0.0002,
			],
		},
		{
			id: 25,
			name: lang.anti_coccidial,
			values: [0, 0, 0, 0, 0, 0, 0, 0, 0.012, 0, 0, 0, 0, 0, 0, 0],
		},
		{
			id: 26,
			name: lang.anti_mycotoxines,
			values: [0, 0, 0, 0, 0, 0, 0, 0, 0.015, 0, 0, 0, 0, 0, 0, 0],
		},
	];
	const [selectedFeed, setSelectedFeed] = useState(1); // For selecting feed type
	const [price, setPrice] = useState(""); // For inputting price
	const [quantity, setQuantity] = useState(""); // For inputting quantity
	const [typesText, setTypesText] = useState(""); // For tracking feed types used
	const [feedList, setFeedList] = useState([]);
	const [feedName, setFeedName] = useState(feedTypes[1].name);
	const [showModal, setShowModal] = useState(false);
	const [showResult, setShowResult] = useState(false);

	// Sum states for each field
	const [totalAmountOfAll, setTotalAmountOfAll] = useState(0);
	const [totalCostOfAll, setTotalCostOfAll] = useState(0);
	const [sumProtien, setSumProtien] = useState(0);
	const [sumEnergy, setSumEnergy] = useState(0);
	const [sumFiber, setSumFiber] = useState(0);
	const [sumCa, setSumCa] = useState(0); // Calcium
	const [sumPh, setSumPh] = useState(0); // Phosphorus
	const [sumLysine, setSumLysine] = useState(0);
	const [sumMethionine, setSumMethionine] = useState(0);
	const [sumThreonine, setSumThreonine] = useState(0);
	const [sumTryptophan, setSumTryptophan] = useState(0);
	const [sumSodium, setSumSodium] = useState(0);
	const [sumChlorine, setSumChlorine] = useState(0);
	const [sumPotassium, setSumPotassium] = useState(0);
	const [sumMg, setSumMg] = useState(0); // Magnesium

	useEffect(() => {
		console.log("Updated feedList:", feedList);
	}, [feedList]);

	const handleAddFeed = () => {
		if (selectedFeed && quantity) {
			// Find the feed based on the selectedFeed ID
			const feed = feedTypes.find((feed) => feed.id === Number(selectedFeed));

			// Debugging: log feed and selectedFeed
			console.log("Selected feed ID:", typeof selectedFeed);
			console.log("Feed found:", feed);

			// Check if feed is found
			if (feed) {
				console.log("prevFeedList", feed.name);

				// Add feed to the feed list
				setFeedList((prevFeedList) => [
					...prevFeedList,
					{
						feed: feed.name, // Only access feed.name if feed exists
						price,
						quantity,
					},
				]);
			} else {
				// Handle the case where the feed is not found
				console.error("Feed not found for selectedFeed:", selectedFeed);
			}
		}
	};

	const handleSendData = () => {
		if (quantity === "") {
			setShowModal(true);
			return false;
		}
		const feed = feedTypes.find((f) => f.id === Number(selectedFeed));
		setFeedName(feed.name);
		if (feed) {
			// Update the text for types used
			const newTypesText = `${typesText}\n - ${quantity} ${lang.kilo_gram} ${lang.from} ${feed.name}`;
			setTypesText(newTypesText);
			setFeedName(feed.name);

			// Update the sum states based on the selected feed and its values
			setSumProtien((prev) => prev + feed.values[2] * quantity);
			setSumEnergy((prev) => prev + feed.values[3] * quantity);
			setSumFiber((prev) => prev + feed.values[4] * quantity);
			setSumCa((prev) => prev + feed.values[5] * quantity);
			setSumPh((prev) => prev + feed.values[6] * quantity);
			setSumLysine((prev) => prev + feed.values[7] * quantity);
			setSumMethionine((prev) => prev + feed.values[8] * quantity);
			setSumThreonine((prev) => prev + feed.values[9] * quantity);
			setSumTryptophan((prev) => prev + feed.values[10] * quantity);
			setSumSodium((prev) => prev + feed.values[11] * quantity);
			setSumChlorine((prev) => prev + feed.values[12] * quantity);
			setSumPotassium((prev) => prev + feed.values[13] * quantity);
			setSumMg((prev) => prev + feed.values[14] * quantity);
			// setSumFe((prev) => prev + feed.values[15] * quantity);
			// setSumZn((prev) => prev + feed.values[16] * quantity);
			// setSumCu((prev) => prev + feed.values[17] * quantity);
			// setSumMn((prev) => prev + feed.values[18] * quantity);

			// Total Amount
			setTotalAmountOfAll(Number(totalAmountOfAll) + Number(quantity));

			// Total Price
			setTotalCostOfAll(Number(totalCostOfAll) + Number(price));
			handleAddFeed();
			setShowResult(false);
		}
	};

	const handleReset = () => {
		setPrice("");
		setQuantity("");
		setTypesText("");
		setTotalAmountOfAll(0);
		setTotalCostOfAll(0);

		//
		setSumProtien(0);
		setSumEnergy(0);
		setSumFiber(0);
		setSumEnergy(0);
		setSumFiber(0);
		setSumCa(0);
		setSumPh(0);
		setSumLysine(0);
		setSumMethionine(0);
		setSumThreonine(0);
		setSumTryptophan(0);
		setSumSodium(0);
		setSumChlorine(0);
		setSumPotassium(0);
		setSumMg(0);

		setFeedList([]);
		setShowResult(false);
	};

	const handleAnalysis = () => {
		setShowResult(true);
		setFeedList([]);
		setTypesText("");

		if (typesText === "") {
			setShowResult(false);
			setShowModal(true);
		}
	};

	const closeModal = () => {
		setShowModal(false);
	};

	useEnterKeyPress(handleSendData);

	return (
		<section
			dir={language === "ar" ? "rtl" : "ltr"}
			className='container pt-150'
		>
			<h1>{lang.feed_Analysis}</h1>

			{/* Dropdown for selecting feed type */}
			<div className='calc-result'>
				<div className='first-box'>
					<div className='main-calc'>
						<div className='box'>
							<label>{lang.Feed}: </label>
							<select
								value={selectedFeed}
								onChange={(e) => {
									setSelectedFeed(e.target.value);
									setFeedName(e.target.value);
								}}
							>
								{feedTypes.map((feed) => (
									<option key={feed.id} value={feed.id}>
										{feed.name}
									</option>
								))}
							</select>
						</div>

						{/* Input fields for price and quantity */}
						<div className='box'>
							<label>{lang.Price}: </label>
							<input
								id='price'
								type='number'
								value={price}
								onChange={(e) => setPrice(e.target.value)}
								placeholder={lang.Enter_Price}
							/>
						</div>
						<div className='box'>
							<label>{lang.Quantity}: </label>
							<input
								id='quantity'
								type='number'
								value={quantity}
								onChange={(e) => setQuantity(e.target.value)}
								placeholder={lang.Enter_Quantity}
							/>
						</div>
					</div>
					<div className='data'>
						<div>
							<table>
								{/* <thead>
									<tr>
										<th>Feed</th>
										<th style={{ width: "200px" }}>Price</th>
										<th style={{ width: "200px" }}>Quantity</th>
									</tr>
								</thead> */}
								<tbody>
									{feedList.map((item, index) => (
										<tr key={index}>
											<td>{item.feed}</td>
											<td style={{ width: "200px" }}>{item.price}</td>
											<td style={{ width: "200px" }}>{item.quantity}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<div className='total'>
							<p>{lang.Total}</p>
							<p>{totalCostOfAll}</p>
							<p>{totalAmountOfAll}</p>
						</div>
					</div>
				</div>

				{/* Display the types used */}
				<textarea value={typesText} readOnly rows='10' cols='30' />
			</div>

			<div className='actions'>
				{/* Button to add feed type and update sums */}
				<button className='btn appointment-btn' onClick={handleSendData}>
					{lang.Add_Feed_Data}
				</button>

				{/* Button to perform analysis */}
				<button className='btn appointment-btn' onClick={handleAnalysis}>
					{lang.Analyze}
				</button>

				{/* Button to perform analysis */}
				<button className='btn appointment-btn' onClick={handleReset}>
					{lang.Reset}
				</button>
			</div>

			{/* Display calculated sums */}
			{showResult && (
				<div>
					<h2>{lang.Results}</h2>
					<table>
						<thead>
							<tr>
								<th>{lang.Nutrient}</th>
								<th>{lang.Value}</th>
								<th>{lang.Nutrient}</th>
								<th>{lang.Value}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{lang.Quantity}</td>
								<td>{totalAmountOfAll}</td>
								<td>{lang.Price}</td>
								<td>{totalCostOfAll}</td>
							</tr>
							<tr>
								<td>{lang.Protein}</td>
								<td>{sumProtien.toFixed(2)}</td>
								<td>{lang.Calcium_Ca}</td>
								<td>{sumCa.toFixed(2)}</td>
							</tr>
							<tr>
								<td>{lang.Energy}</td>
								<td>{sumEnergy.toFixed(2)}</td>
								<td>{lang.Phosphorus_Ph}</td>
								<td>{sumPh.toFixed(2)}</td>
							</tr>
							<tr>
								<td>{lang.Fiber}</td>
								<td>{sumFiber.toFixed(2)}</td>
								<td>{lang.Lysine}</td>
								<td>{sumLysine.toFixed(2)}</td>
							</tr>
							<tr>
								<td>{lang.Sodium}</td>
								<td>{sumSodium.toFixed(2)}</td>
								<td>{lang.Methionine}</td>
								<td>{sumMethionine.toFixed(2)}</td>
							</tr>
							<tr>
								<td>{lang.Chlorine}</td>
								<td>{sumChlorine.toFixed(2)}</td>
								<td>{lang.Threonine}</td>
								<td>{sumThreonine.toFixed(2)}</td>
							</tr>
							<tr>
								<td>{lang.Potassium}</td>
								<td>{sumPotassium.toFixed(2)}</td>
								<td>{lang.Tryptophan}</td>
								<td>{sumTryptophan.toFixed(2)}</td>
							</tr>
							<tr>
								<td>{lang.Magnesium_Mg}</td>
								<td>{sumMg.toFixed(2)}</td>
							</tr>
						</tbody>
					</table>
				</div>
			)}

			{/* Modal Component */}
			{showModal && (
				<div className='modal-overlay'>
					<div className='modal-content'>
						<h2>{lang.Warning}</h2>
						<p>{lang.No_data_entered_for_quantity}</p>
						<button onClick={closeModal}>{lang.Close}</button>
					</div>
				</div>
			)}
		</section>
	);
}

export default FeedCalculator;
