import React from "react";
import "./SuggestedServices.scss";
import Service from "../../components/Service/Service";
import { Link } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
import { useSelector } from "react-redux";
const SuggestedServices = ({ servicesData }) => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en" ? en.hero : language === "ar" ? ar.hero : fr.hero;

	// const servicesData = useServicesData();
	// const { title, description, icon } = serviceList;
	return (
		<section
			className='service-section pt-100 pb-70'
			data-aos='fade-up'
			data-aos-duration='2000'
		>
			<div className='container'>
				<div className='row' style={{ justifyContent: "space-between" }}>
					{servicesData.map((singleService) => (
						<Service serviceList={singleService} />
					))}
				</div>
			</div>

			<div className='services-link text-center'>
				<Link to='/services' dir={language === "ar" && "ltr"}>
					{lang.View_all_service_list}
					<BsFillArrowRightCircleFill />
				</Link>
			</div>
		</section>
	);
};

export default SuggestedServices;
