import { createSlice } from "@reduxjs/toolkit";
import { drugInteractionsProgram } from "../services/DrugInteractionsProgram/drugInteractionsProgram";

const initialState = {
	isLoading: false,
	isLoadingCreate: false,
	getMedicines: [],
};

const drugInteractionsSlice = createSlice({
	name: "drugInteractions", // Updated name
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(drugInteractionsProgram.fulfilled, (state, action) => {
				state.isLoading = false;
				state.getMedicines = action.payload;
			})
			.addCase(drugInteractionsProgram.rejected, (state) => {
				state.isLoading = false;
			})
			.addCase(drugInteractionsProgram.pending, (state) => {
				state.isLoading = true;
			});
	},
});

export default drugInteractionsSlice.reducer;
