import React from "react";
import SectionTitle from "../components/SectionTitle/SectionTitle";

const VideoSection = ({
	title,
	title2,
	description,
	description2,
	subTitle,
	subTitle2,
	vidoLink,
	vidoLink2,
	bgColor,
}) => {
	return (
		<section
			className='safty-section pt-100 section-common pb-70'
			data-aos='fade-up'
			data-aos-duration='2000'
			style={{ backgroundColor: bgColor }}
		>
			<div className='container'>
				<SectionTitle
					// subTitle={subTitle}
					title={title}
					description={description}
				/>

				<div className='safety-video ratio ratio-16x9'>
					<iframe
						style={{ borderRadius: "14px" }}
						src={vidoLink}
						title={title}
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowfullscreen
					></iframe>
				</div>
			</div>
			<div className='container'>
				<SectionTitle
					// subTitle={subTitle2}
					title={title2}
					description={description2}
				/>

				<div className='safety-video ratio ratio-16x9'>
					<iframe
						style={{ borderRadius: "14px" }}
						src={vidoLink2}
						title={title2}
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowfullscreen
					></iframe>
				</div>
			</div>
		</section>
	);
};

export default VideoSection;
