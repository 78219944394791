import React from "react";
import PageTitle from "../components/PageTitle/PageTitle";
import BlogCard from "../components/BlogCard/BlogCard";
import BlogData from "../sections/Blogs/BlogData";
import ar from "../lang/ar/ar.json";
import en from "../lang/en/en.json";
import fr from "../lang/fr/fr.json";
import { useSelector } from "react-redux";

const BlogsPage = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en" ? en.blogs : language === "ar" ? ar.blogs : fr.blogs;
	return (
		<>
			<PageTitle title={lang.blogs_welcome} description={lang.intro} />
			<section className='pb-70' data-aos='fade-up' data-aos-duration='2000'>
				<div className='container'>
					<div className='row'>
						{BlogData.map((blog) => (
							<BlogCard blog={blog} />
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default BlogsPage;
