import React from "react";

const InputField = ({
	label,
	value,
	onChange,
	placeholder,
	type = "number",
	icon,
}) => {
	return (
		<div className='form-group'>
			<label>{label}</label>
			{icon && (
				<img src={icon} alt='icon' style={{ width: 20, marginRight: 10 }} />
			)}
			<input
				className='form-control'
				type={type}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
			/>
		</div>
	);
};

export default InputField;
