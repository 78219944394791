import React from "react";
import { Link } from "react-router-dom";
import ThemeIcon from "../ThemeIcon/ThemeIcon";
import "./Service.scss";

const Service = ({ serviceList }) => {
	console.log("serviceList", serviceList);
	const { title, description, icon, url } = serviceList;

	return (
		<div className='col-lg-3 col-md-4 col-sm-6 d-flex'>
			<div className='service-box d-flex flex-column h-100'>
				<div className='service-icon'>
					<div className='icon-area'>
						<Link to={url}>
							<ThemeIcon icon={icon} />
						</Link>
					</div>
				</div>
				<div className='service-text mt-auto d-flex flex-column gap-2 flex-fill'>
					<h3>
						<Link to={url}>{title}</Link>
					</h3>
					<Link to={url} style={{ textDecoration: "none" }}>
						<p>{description}</p>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Service;
