import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
import icon from "../../../../../assets/icons/Feed Consumption Rate Program.webp"; // Updated icon
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";

function TotalFeedConsumptionForWhiteLayingHensProgramTwo() {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");
	const [weeks, setWeeks] = useState("");

	// The CFI array (equivalent to your cumulative feed intake array)
	const CFI = [
		5346, 5414, 5483, 5553, 5624, 5696, 5769, 5858, 5948, 6039, 6131, 6224,
		6318, 6413, 6509, 6606, 6704, 6803, 6903, 7003, 7103, 7204, 7305, 7407,
		7510, 7614, 7719, 7825, 7932, 8040, 8149, 8259, 8370, 8482, 8595, 8708,
		8822, 8936, 9051, 9166, 9282, 9398, 9514, 9630, 9747, 9864, 9981, 10099,
		10217, 10335, 10454, 10573, 10692, 10811, 10931, 11051, 11171, 11291, 11411,
		11531, 11651, 11771, 11891, 12012, 12133, 12254, 12375, 12497, 12619, 12741,
		12863, 12985, 13107, 13229, 13351, 13473, 13595, 13717, 13839, 13961, 14083,
		14205, 14327, 14449, 14571, 14693, 14815, 14937, 15059, 15181, 15303, 15425,
		15547, 15669, 15791, 15913, 16035, 16157, 16279, 16401, 16523, 16645, 16767,
		16889, 17011, 17133, 17255, 17377, 17499, 17621, 17743, 17865, 17987, 18109,
		18231, 18353, 18475, 18597, 18719, 18841, 18963, 19085, 19207, 19329, 19451,
		19573, 19695, 19817, 19939, 20061, 20183, 20305, 20427, 20549, 20671, 20793,
		20915, 21037, 21159, 21281, 21403, 21525, 21647, 21769, 21891, 22013, 22135,
		22257, 22379, 22501, 22623, 22745, 22867, 22989, 23111, 23233, 23355, 23477,
		23599, 23721, 23843, 23965, 24087, 24209, 24331, 24453, 24575, 24697, 24819,
		24941, 25063, 25185, 25307, 25429, 25551, 25673, 25795, 25917, 26039, 26161,
		26283, 26405, 26527, 26649, 26771, 26893, 27015, 27137, 27259, 27381, 27503,
		27625, 27747, 27869, 27991, 28113, 28235, 28357, 28479, 28601, 28723, 28845,
		28967, 29089, 29211, 29333, 29455, 29577, 29699, 29821, 29943, 30065, 30187,
		30309, 30431, 30553, 30675, 30797, 30919, 31041, 31163, 31285, 31407, 31529,
		31651, 31773, 31895, 32017, 32139, 32261, 32383, 32505, 32627, 32749, 32871,
		32993, 33115, 33237, 33359, 33481, 33603, 33725, 33847, 33969, 34091, 34213,
		34335, 34457, 34579, 34701, 34823, 34945, 35067, 35189, 35311, 35433, 35555,
		35677, 35799, 35921, 36043, 36165, 36287, 36409, 36531, 36653, 36775, 36898,
		37021, 37144, 37267, 37390, 37513, 37636, 37759, 37882, 38005, 38128, 38251,
		38374, 38497, 38620, 38743, 38866, 38989, 39112, 39235, 39358, 39481, 39604,
		39727, 39850, 39973, 40096, 40219, 40342, 40465, 40588, 40711, 40834, 40957,
		41080, 41203, 41326, 41449, 41572, 41695, 41818, 41941, 42064, 42187, 42310,
		42433, 42556, 42679, 42802, 42925, 43048, 43171, 43294, 43417, 43540, 43663,
		43786, 43909, 44032, 44155, 44278, 44401, 44524, 44647, 44770, 44893, 45016,
		45139, 45262, 45385, 45508, 45631, 45754, 45877, 46000, 46123, 46246, 46369,
		46492, 46615, 46738, 46861, 46984, 47107, 47230, 47353, 47476, 47599, 47722,
		47845, 47968, 48091, 48214, 48337, 48460, 48583, 48706, 48829, 48952, 49075,
		49198, 49321, 49444, 49567, 49690, 49813, 49936, 50059, 50182, 50305, 50428,
		50551, 50674, 50797, 50920, 51043, 51166, 51289, 51412, 51535, 51658, 51781,
		51904, 52027, 52150, 52273, 52396, 52519, 52642, 52765, 52888, 53011, 53134,
		53257, 53380, 53503, 53626, 53749, 53872, 53995, 54118, 54241, 54364, 54487,
		54610, 54733, 54856, 54979, 55102, 55225, 55348, 55471, 55594, 55717, 55840,
		55963, 56086, 56209, 56332, 56455, 56578, 56701, 56824, 56947, 57070, 57193,
		57316, 57439, 57562, 57685, 57808, 57931, 58054, 58177, 58300, 58423, 58546,
		58669, 58792, 58915, 59038, 59161, 59284, 59407, 59530, 59653, 59776, 59899,
		60022, 60145, 60268, 60391, 60514, 60638, 60762, 60886, 61010, 61134, 61258,
		61382, 61506, 61630, 61754, 61878, 62002, 62126, 62250, 62374, 62498, 62622,
		62746, 62870, 62994, 63118, 63242, 63366, 63490, 63614, 63738, 63862, 63986,
		64110, 64234, 64358, 64482, 64606, 64730, 64854, 64978, 65102, 65226, 65350,
		65474, 65598, 65722, 65846, 65970, 66094, 66218, 66342, 66466, 66590, 66714,
		66838, 66962, 67086, 67210, 67334, 67458, 67582, 67706, 67830, 67954, 68078,
		68202, 68326, 68450, 68574, 68698, 68822, 68946, 69070, 69194, 69318, 69442,
		69566, 69690, 69814, 69938, 70062, 70186, 70310,
	];

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateBtnFunction = () => {
		const age = parseInt(ageOfBirds);
		const number = parseInt(numberOfBirds);

		if (!isNaN(age) && !isNaN(number)) {
			const calculatedWeeks = Math.ceil(age / 7);
			setWeeks(calculatedWeeks);

			const index = age - 120;
			if (index >= 0 && index < CFI.length) {
				const res1 = (CFI[index] * number) / 1000;
				const res2 = res1 / 1000;
				setResult(
					`${lang.NormalCumulativeFeedIntake} = ${res1} ${lang.Kg} = ${res2} ${newLang.ton} \n${calculatedWeeks} ${newLang.weeks}`
				);
			} else {
				setResult(lang.InvalidInputMessage);
			}
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateBtnFunction);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.brownLayingHensManagmentPrograms
						.TotalFeedConsumptionForWhiteLayingHensProgramTwo
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateBtnFunction}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={icon}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
}

export default TotalFeedConsumptionForWhiteLayingHensProgramTwo;
