import React from "react";
import MainFourServisesLayout from "../../../../components/MainFourServisesLayout/MainFourServisesLayout";
import icon1 from "../../../../assets/icons/Production Guide For Brown Laying Hens.webp";
import icon2 from "../../../../assets/icons/Water Consumption Rate Program.webp"; // not
import icon3 from "../../../../assets/icons/Feed Consumption Rate Program.webp"; // not
import icon4 from "../../../../assets/icons/Total Feed Consumption For White Laying Hens Program.webp";
import icon5 from "../../../../assets/icons/Normal Body Weight For Brown Laying Hens Program.webp";
import icon6 from "../../../../assets/icons/Average Egg Weight For Brown Laying Hens Program.webp";
import icon7 from "../../../../assets/icons/Egg Percentage For Brown Laying Hens Program.webp";
import icon8 from "../../../../assets/icons/Cumulative Egg Production For Brown Laying Hens Program.webp";

import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import { useSelector } from "react-redux";
function BrownLayingHensManagmentPrograms() {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.layersManagment.brownLayingHensManagmentPrograms
			: language === "ar"
			? ar.services.layersManagment.brownLayingHensManagmentPrograms
			: fr.services.layersManagment.brownLayingHensManagmentPrograms;

	const brownLayingHensManagmentProgramsServices = [
		{
			id: 1,
			title: lang.ProductionGuideForBrownLayingHensProgram, // not
			description: lang.ProductionGuideForBrownLayingHensProgram,
			imgSrc: icon1,
			link: "production-guide-for-brown-laying-hens-program",
		},
		{
			id: 2,
			title: lang.WaterConsumptionRateForBrownLayingHensProgram,
			description: lang.WaterConsumptionRateForBrownLayingHensProgram,
			imgSrc: icon2,
			link: "water-consumption-rate-for-brown-laying-hens-program",
		},
		{
			id: 3,
			title: lang.FeedConsumptionRateForBrownLayingHensProgram,
			description: lang.FeedConsumptionRateForBrownLayingHensProgram,
			imgSrc: icon3,
			link: "feed-consumption-rate-for-brown-laying-hens-program",
		},
		{
			id: 4,
			title: lang.TotalFeedConsumptionForWhiteLayingHensProgramTwo,
			description: lang.TotalFeedConsumptionForWhiteLayingHensProgramTwo,
			imgSrc: icon4,
			link: "total-feed-consumption-for-white-laying-hens-program-two",
		},
		{
			id: 5,
			title: lang.NormalBodyWeightForBrownLayingHensProgram, // not sure
			description: lang.NormalBodyWeightForBrownLayingHensProgram,
			imgSrc: icon5,
			link: "normal-body-weight-for-brown-laying-hens-program",
		},
		{
			id: 6,
			title: lang.AverageEggWeightForBrownLayingHensProgram,
			description: lang.AverageEggWeightForBrownLayingHensProgram,
			imgSrc: icon6,
			link: "average-egg-weight-for-brown-laying-hens-program",
		},
		{
			id: 7,
			title: lang.EggPercentageForBrownLayingHensProgram,
			description: lang.EggPercentageForBrownLayingHensProgram,
			imgSrc: icon7,
			link: "egg-percent-age-for-brown-laying-hens-program",
		},
		{
			id: 8,
			title: lang.CumulativeEggProductionForBrownLayingHensProgram,
			description: lang.CumulativeEggProductionForBrownLayingHensProgram,
			imgSrc: icon8,
			link: "cumulative-egg-production-for-brown-laying-hens-program",
		},
	];
	return (
		<MainFourServisesLayout
			serviceData={brownLayingHensManagmentProgramsServices}
			title={lang.title}
			isShowSuggestedServices={false}
		/>
	);
}

export default BrownLayingHensManagmentPrograms;
