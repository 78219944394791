import React, { useEffect } from "react";
import Appointment from "../sections/Appointment/Appointment";
import Banner from "../sections/Banner/Banner";
import Blogs from "../sections/Blogs/Blogs";
import Emergency from "../sections/Emergency/Emergency";
import Expert from "../sections/Expert/Expert";
import Features from "../sections/Features/Features";
import Services from "../sections/Services/Services";
import Testimonial from "../sections/Testimonial/Testimonial";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../redux/slices/langSlice";
import ChangeLang from "../utils/ChangeLang";
import ar from "../lang/ar/ar.json";
import en from "../lang/en/en.json";
import fr from "../lang/fr/fr.json";

const Home = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en" ? en.navbar : language === "ar" ? ar.navbar : fr.navbar;
	return (
		<div className='main-home'>
			<Banner />
			<Services />
			<Emergency />
			{/* <Features /> */}
			<Expert />
			<Testimonial />
			<Blogs />
			<Appointment />
		</div>
	);
};

export default Home;
