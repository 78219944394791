import Navbar from "../../components/Navbar/Navbar";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import "./Register.scss";

import React from "react";
import RegisterForm from "./RegisterForm";
import { useSelector } from "react-redux";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
function Register() {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.register
			: language === "ar"
			? ar.register
			: fr.register;
	return (
		<>
			<section
				className='section-bg section-common contact-section'
				style={{ height: 400 }}
			>
				<Navbar />
				<SectionTitle title={lang.title} description={lang.description} />
			</section>
			<section
				className='contact-form-area'
				data-aos='fade-up'
				data-aos-duration='2000'
			>
				<RegisterForm lang={lang} />
			</section>
		</>
	);
}

export default Register;
