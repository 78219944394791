import React, { useState } from "react";
import { useSelector } from "react-redux";
import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import icon from "../../../../assets/icons/Livability Percentage Program.webp"; // Updated icon for Livability
import useEnterKeyPress from "../../../../utils/EnterCalculateBtn";
import InputField from "../../../../components/Calculations/InputField"; // Reused InputField component

const LivabilityPercentageProgram = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [deadBirds, setDeadBirds] = useState("");
	const [result, setResult] = useState("");

	// Get the language from the Redux store
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	// Calculate the Livability percentage
	const calculateLivability = () => {
		const numBirds = parseFloat(numberOfBirds);
		const dead = parseFloat(deadBirds);

		if (
			!isNaN(numBirds) &&
			!isNaN(dead) &&
			numBirds >= 0 &&
			dead >= 0 &&
			dead <= numBirds
		) {
			const mortalityPercent = (dead / numBirds) * 100;
			const livabilityPercent = 100 - mortalityPercent;

			setResult(
				`${lang.LivabilityPercent} = ${livabilityPercent.toFixed(0)} %`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	// Trigger the calculation when Enter key is pressed
	useEnterKeyPress(calculateLivability);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>{lang.LivabilityCalculator}</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.DeadBirds}:`}
						value={deadBirds}
						onChange={(e) => setDeadBirds(e.target.value)}
						placeholder={lang.EnterDeadBirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateLivability}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={icon}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
};

export default LivabilityPercentageProgram;
