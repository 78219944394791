import React from "react";
import "./Testimonial.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import imgOne from "../../assets/testimonial/1.png";
import imgTwo from "../../assets/testimonial/2.png";
import imgThree from "../../assets/testimonial/3.png";
import TestimoniCard from "../../components/TestimoniCard/TestimoniCard";
import { AiFillStar } from "react-icons/ai";
import Slider from "react-slick";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
import { useSelector } from "react-redux";

const Testimonial = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.commmints
			: language === "ar"
			? ar.commmints
			: fr.commmints;
	const testimonails = [
		{
			img: imgOne,
			name: "Robert Fox",
			description:
				"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts",
			ratings: [
				<AiFillStar />,
				<AiFillStar />,
				<AiFillStar />,
				<AiFillStar />,
				<AiFillStar />,
			],
		},
		{
			img: imgTwo,
			name: "Albert Flores",
			description:
				"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts",
			ratings: [
				<AiFillStar />,
				<AiFillStar />,
				<AiFillStar />,
				<AiFillStar />,
				<AiFillStar />,
			],
		},
		{
			img: imgThree,
			name: "Bessie Cooper",
			description:
				"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts",
			ratings: [
				<AiFillStar />,
				<AiFillStar />,
				<AiFillStar />,
				<AiFillStar />,
				<AiFillStar />,
			],
		},
		{
			img: imgOne,
			name: "Bessie Cooper",
			description:
				"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts",
			ratings: [
				<AiFillStar />,
				<AiFillStar />,
				<AiFillStar />,
				<AiFillStar />,
				<AiFillStar />,
			],
		},
	];

	// slider
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<section
			className='testimonail-section section-bg section-common pt-100 pb-70'
			data-aos='fade-up'
			data-aos-duration='2000'
		>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-6'>
						<SectionTitle
							subTitle={lang.testimonial}
							title={lang.what_people_say}
						/>
					</div>
					<div className='col-lg-6'>
						<p className='pt-5'>{lang.opinions}</p>
					</div>
				</div>

				<Slider {...settings} className='testimoni-slider'>
					{testimonails.map((testimonail) => (
						<TestimoniCard testimonail={testimonail} />
					))}
				</Slider>
			</div>
		</section>
	);
};

export default Testimonial;
