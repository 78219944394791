import React from "react";
import VideoSection from "../../shared/VideoSection";
import "./Videos.scss";
function MainVideos() {
	return (
		<div className='pt-100'>
			<div className='service-section pt-100 pb-70 '>
				<VideoSection
					title={"Humidity problems in poultry farm"}
					description={
						"Humidity problems in poultry farm Humidity problems in poultry farm Humidity problems in poultry farm"
					}
					subTitle={"Videos"}
					vidoLink={
						"https://www.youtube.com/embed/videoseries?list=PL7tvY4gUhh-Tu4OmAps_y_q6S717eN7XG&si=nFDP5W4Y0XiQ4WnB"
					}
					bgColor={"#dbeffa"}
					title2={"Humidity problems in poultry farm"}
					description2={
						"Humidity problems in poultry farm Humidity problems in poultry farm Humidity problems in poultry farm"
					}
					subTitle2={"Videos"}
					vidoLink2={
						"https://www.youtube.com/embed/videoseries?list=PL7tvY4gUhh-SEqa-Z5WzuRugnib1GtpTF&si=7AMYLB3LDvbiDdG1"
					}
				/>
			</div>
			<div className='service-section  pb-70 '>
				<VideoSection
					title={"Humidity problems in poultry farm"}
					description={
						"Humidity problems in poultry farm Humidity problems in poultry farm Humidity problems in poultry farm"
					}
					subTitle={"Videos"}
					vidoLink={
						"https://www.youtube.com/embed/videoseries?list=PL7tvY4gUhh-S1gPCFLZUj6EJl_-OgiouU&si=RWXARYzuzMVWOZi-"
					}
					bgColor={"#dbfae0"}
					title2={"Humidity problems in poultry farm"}
					description2={
						"Humidity problems in poultry farm Humidity problems in poultry farm Humidity problems in poultry farm"
					}
					subTitle2={"Videos"}
					vidoLink2={
						"https://www.youtube.com/embed/videoseries?list=PL7tvY4gUhh-RX4_LBgkmMwAnGKakva0vu&si=7L-O5V7nKLSkZEIO"
					}
				/>
			</div>
			<div className='service-section  pb-70 '>
				<VideoSection
					title={"Humidity problems in poultry farm"}
					description={
						"Humidity problems in poultry farm Humidity problems in poultry farm Humidity problems in poultry farm"
					}
					subTitle={"Videos"}
					vidoLink={
						"https://www.youtube.com/embed/videoseries?list=PL7tvY4gUhh-SRNqfHhBhUuqyh_2-qkUrQ&si=3s1ANiHC_MeyxMMj"
					}
					bgColor={"#ff00002e"}
					title2={"Humidity problems in poultry farm"}
					description2={
						"Humidity problems in poultry farm Humidity problems in poultry farm Humidity problems in poultry farm"
					}
					subTitle2={"Videos"}
					vidoLink2={
						"https://www.youtube.com/embed/videoseries?list=PL7tvY4gUhh-Qe-kQN0oHx5AiHChgW59fQ&si=NrcGV9iPooTwhirc"
					}
				/>
			</div>
			<div className='service-section  pb-70 '>
				<VideoSection
					title={"Humidity problems in poultry farm"}
					description={
						"Humidity problems in poultry farm Humidity problems in poultry farm Humidity problems in poultry farm"
					}
					subTitle={"Videos"}
					vidoLink={
						"https://www.youtube.com/embed/videoseries?list=PL7tvY4gUhh-SkqX-79JzpaJj9y7cGNccR&si=cFQ6lg1SJ6QX5pkr"
					}
					bgColor={"#0019ff2e"}
					title2={"Humidity problems in poultry farm"}
					description2={
						"Humidity problems in poultry farm Humidity problems in poultry farm Humidity problems in poultry farm"
					}
					subTitle2={"Videos"}
					vidoLink2={
						"https://www.youtube.com/embed/videoseries?list=PL7tvY4gUhh-R6oHJLyTMAC7p98RA-xEMT&si=ZtQE6IgJd0BXv_yz"
					}
				/>
			</div>
		</div>
	);
}

export default MainVideos;
