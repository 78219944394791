import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
import icon from "../../../../../assets/icons/Water Consumption Rate Program.webp"; // Updated icon
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";

function WaterConsumptionRateForWhitepulletsPrograms() {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");
	const [weeks, setWeeks] = useState(""); // Added state for weeks

	// Water consumption data (equivalent to PHP array)
	const water = [
		0, 20, 21, 22, 23, 24, 25, 25, 25, 26, 26, 27, 27, 28, 28, 28, 29, 29, 30,
		30, 31, 31, 33, 35, 37, 39, 41, 43, 45, 47, 49, 51, 53, 55, 57, 60, 62, 64,
		66, 68, 70, 72, 74, 75, 76, 77, 77, 78, 78, 78, 79, 79, 80, 80, 81, 82, 83,
		84, 84, 85, 85, 86, 86, 87, 88, 89, 89, 90, 90, 90, 90, 91, 91, 92, 92, 92,
		93, 93, 94, 94, 94, 95, 95, 96, 96, 97, 97, 98, 98, 99, 99, 100, 100, 100,
		101, 101, 102, 102, 102, 103, 103, 103, 104, 104, 104, 105, 105, 105, 106,
		106, 106, 107, 107, 107, 107, 107, 108, 108, 108, 108, 109, 109, 110, 110,
		110, 110, 110,
	];

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const age = parseFloat(ageOfBirds);

		if (!isNaN(numBirds) && !isNaN(age) && numBirds >= 0 && age >= 0) {
			// Calculate the week based on the bird's age
			const weeksCalculated = Math.ceil(age / 7);
			setWeeks(weeksCalculated); // Still setting weeks in the state

			// Water consumption logic based on age and number of birds
			const waterConsumption = (water[age] * numBirds) / 1000;

			// Update result with weeksCalculated directly
			setResult(
				`${newLang.NormalWaterConsumption} = ${waterConsumption} ${lang.Liter} \n${weeksCalculated} ${newLang.weeks}
				 `
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.whitePulletsManagmentPrograms
						.WaterConsumptionRateForWhitepulletsPrograms
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					{/* <div>
						<InputField
							label={`${lang.WeeksCalculated}:`}
							value={weeks}
							placeholder={lang.WeeksCalculatedPlaceholder}
							readOnly
						/>
					</div> */}
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={icon}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
}

export default WaterConsumptionRateForWhitepulletsPrograms;
