import React, { useState } from "react";
import { useSelector } from "react-redux";
import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import { Link } from "react-router-dom";
import icon from "../../../../assets/icons/Lighting Rate Program.webp";
import useEnterKeyPress from "../../../../utils/EnterCalculateBtn";
import InputField from "../../../../components/Calculations/InputField";

function LightingRateProgram() {
	const [age, setAge] = useState("");
	const [area, setArea] = useState("");
	const [result, setResult] = useState("");

	const light_intensity = [
		30, 30, 30, 30, 30, 30, 30, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 10.8,
		10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8,
		10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8,
		10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8,
		10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8,
		10.8,
	];

	const lighting_hours = [
		24, 23, 23, 23, 22, 22, 22, 22, 21, 21, 21, 21, 21, 20, 20, 20, 20, 20, 20,
		20, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 20, 20, 20, 20, 20, 20, 20, 20,
		20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
		20, 20, 20,
	];

	const calculateResults = () => {
		const ageIndex = parseInt(age, 10);
		const areaValue = parseFloat(area);

		if (
			!isNaN(ageIndex) &&
			!isNaN(areaValue) &&
			light_intensity[ageIndex] !== undefined
		) {
			const res1 = light_intensity[ageIndex];
			const res2 = res1 * areaValue;
			const res3 = lighting_hours[ageIndex];

			setResult(
				`Light Intensity = ${res1} Lux / square meter\nTotal Light Intensity = ${res2} Lux\nLighting hours = ${res3} Hours / Day`
			);
		} else {
			setResult("Invalid input. Please enter valid numbers.");
		}
	};

	useEnterKeyPress(calculateResults);
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;
	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>{lang.Lighting_Rate}</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.age} (${lang.days}):`}
						value={age}
						onChange={(e) => setAge(e.target.value)}
						placeholder='Enter age'
					/>
					<InputField
						label={`${lang.area} (m²):`}
						value={area}
						onChange={(e) => setArea(e.target.value)}
						placeholder='Enter area'
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={icon}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
}

export default LightingRateProgram;
