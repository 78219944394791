import React from "react";
import "./Appointment.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { AiFillHome } from "react-icons/ai";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useSelector } from "react-redux";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";

const Appointment = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.contact
			: language === "ar"
			? ar.contact
			: fr.contact;
	const mapLink =
		"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d157186.2175747697!2d31.7800598!3d30.6836844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f131!3m3!1m2!1s0x14f8231d398fa279%3A0x56f4f3e6a82ace8!2sAl-Sawaleh%2C%20Fakous%2C%20Al%20Sharqia%2C%20Egypt!5e0!3m2!1sen!2sbd!4v1693855678321!5m2!1sen!2sbd";

	return (
		<section
			className='appointment-section pb-70'
			data-aos='fade-up'
			data-aos-duration='2000'
		>
			<div className='container'>
				<div className='row align-items-center'>
					<div className='col-lg-5 col-md-6'>
						<div className='google-map'>
							<iframe
								title='map'
								src={mapLink}
							></iframe>

							<div className='location-name'>
								<AiFillHome />
								<p>{lang.addresses}</p>
							</div>
						</div>
					</div>
					<div className='col-lg-7 col-md-6'>
						<div className='appointment-form-area'>
							<SectionTitle
								subTitle={lang.book_now}
								title={lang.title}
								description={lang.description}
							/>

							<ContactForm />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Appointment;
