import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
import icon from "../../../../../assets/icons/Total Feed Consumption For Brown Pullets Program.webp"; // not yet
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";

function TotalFeedConsumptionForWhitePulletsProgram() {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");
	const [weeks, setWeeks] = useState(""); // Added state for weeks

	// Cumulative feed intake data (equivalent to PHP array)
	const cfi = [
		0, 14, 28, 42, 57, 72, 87, 102, 117, 133, 149, 166, 183, 201, 219, 237, 255,
		274, 293, 313, 333, 353, 374, 396, 420, 446, 474, 504, 536, 570, 605, 642,
		680, 719, 759, 800, 841, 883, 925, 968, 1011, 1054, 1097, 1140, 1183, 1226,
		1269, 1313, 1357, 1401, 1446, 1491, 1536, 1582, 1628, 1675, 1722, 1769,
		1817, 1865, 1914, 1963, 2013, 2063, 2113, 2163, 2214, 2265, 2317, 2370,
		2423, 2476, 2529, 2582, 2636, 2690, 2745, 2800, 2855, 2910, 2965, 3021,
		3077, 3133, 3189, 3245, 3301, 3357, 3414, 3471, 3529, 3587, 3645, 3703,
		3761, 3819, 3878, 3937, 3996, 4055, 4114, 4173, 4232, 4292, 4352, 4412,
		4472, 4532, 4592, 4653, 4714, 4775, 4837, 4899, 4961, 5024, 5087, 5150,
		5214, 5279, 5346, 5414, 5483, 5553, 5624, 5696, 5769,
	];

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const age = parseFloat(ageOfBirds);

		if (!isNaN(numBirds) && !isNaN(age) && numBirds >= 0 && age >= 0) {
			// Calculate the week based on the bird's age
			const weeksCalculated = Math.ceil(age / 7);
			setWeeks(weeksCalculated);

			// Cumulative feed intake logic based on age and number of birds
			const cumulativeFeedIntakeKg = (cfi[age] * numBirds) / 1000;
			const cumulativeFeedIntakeTon = cumulativeFeedIntakeKg / 1000;

			// Update result with weeksCalculated
			setResult(
				`${newLang.Normalcumulativefeedintake} = ${cumulativeFeedIntakeKg} ${newLang.Kg} = ${cumulativeFeedIntakeTon} ${newLang.ton}
				\n${weeksCalculated} ${newLang.weeks}`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.whitePulletsManagmentPrograms
						.TotalFeedConsumptionForWhitePulletsProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					{/* Week input field is read-only, similar to the other component */}
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={icon}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
}

export default TotalFeedConsumptionForWhitePulletsProgram;
