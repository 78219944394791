import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../redux/slices/langSlice";
function ChangeLang({ en, ar, fr, handleLinkClick }) {
	const dispatch = useDispatch();

	const language = useSelector((state) => state?.lang?.language);
	const updateSessionStorage = (value) => {
		sessionStorage.setItem("language", JSON.stringify(value));
	};

	const handleSetLanguage = (value) => {
		dispatch(setLanguage(value));
		updateSessionStorage(value);
		handleLinkClick();
	};

	useEffect(() => {
		updateSessionStorage(language);
		const storedLanguage = sessionStorage.getItem("enLanguage");
		if (storedLanguage !== null) {
			setLanguage(JSON.parse(storedLanguage));
		}
	}, [setLanguage, language]);

	console.log("Redux enLanguage:", language);
	return (
		<div className='styled-dropdown'>
			<select onChange={(e) => handleSetLanguage(e.target.value)}>
				<option value='en' key='en'>
					{en}
				</option>
				<option value='ar' key='ar'>
					{ar}
				</option>
				<option value='fr' key='fr'>
					{fr}
				</option>
			</select>
		</div>
	);
}

export default ChangeLang;
