import React from "react";
import MainFourServisesLayout from "../../../../components/MainFourServisesLayout/MainFourServisesLayout";
import icon1 from "../../../../assets/icons/Production Guide For White Laying Hens.webp";
import icon2 from "../../../../assets/icons/Water Consumption Rate Program.webp"; // maybe not
import icon3 from "../../../../assets/icons/Feed Consumption Rate Program.webp"; // maybe not
import icon4 from "../../../../assets/icons/Total Feed Consumption For White Laying Hens Program.webp";
import icon5 from "../../../../assets/icons/Normal Body Weight For White Laying Hens Program.webp";
import icon6 from "../../../../assets/icons/Average Egg Weight For White Laying Hens Program.webp";
import icon7 from "../../../../assets/icons/Egg Percentage For White Laying Hens Program.webp";
import icon8 from "../../../../assets/icons/Cumulative Egg Production For White Laying Hens Program.webp";

import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import { useSelector } from "react-redux";
function WhiteLayingHensManagmentPrograms() {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.layersManagment.whiteLayingHensManagmentPrograms
			: language === "ar"
			? ar.services.layersManagment.whiteLayingHensManagmentPrograms
			: fr.services.layersManagment.whiteLayingHensManagmentPrograms;
	const whiteLayingHensManagmentProgramsServices = [
		{
			id: 1,
			title: lang.ProductionGuideForWhiteLayingHensProgram, // not yet
			description: lang.ProductionGuideForWhiteLayingHensProgram,
			imgSrc: icon1,
			link: "production-guide-for-white-laying-hens-program",
		},
		{
			id: 2,
			title: lang.WaterConsumptionRateForWhiteLayingHensProgram,
			description: lang.WaterConsumptionRateForWhiteLayingHensProgram,
			imgSrc: icon2,
			link: "water-consumption-rate-for-white-laying-hens-program",
		},
		{
			id: 3,
			title: lang.FeedConsumptionRateForWhiteLayingHensProgram, // maybe has something not correct in calculation
			description: lang.FeedConsumptionRateForWhiteLayingHensProgram,
			imgSrc: icon3,
			link: "feed-consumption-rate-for-white-laying-hens-program",
		},
		{
			id: 4,
			title: lang.TotalFeedConsumptionForWhiteLayingHensProgram, // maybe has something not correct in calculation
			description: lang.TotalFeedConsumptionForWhiteLayingHensProgram,
			imgSrc: icon4,
			link: "total-feed-consumption-for-white-laying-hens-program",
		},
		{
			id: 5,
			title: lang.NormalBodyWeightForWhiteLayingHensProgram,
			description: lang.NormalBodyWeightForWhiteLayingHensProgram,
			imgSrc: icon5,
			link: "normal-body-weight-for-white-laying-hens-program",
		},
		{
			id: 6,
			title: lang.AverageEggWeightForWhiteLayingHensProgram,
			description: lang.AverageEggWeightForWhiteLayingHensProgram,
			imgSrc: icon6,
			link: "average-egg-weight-for-white-laying-hens-program",
		},
		{
			id: 7,
			title: lang.EggPercentageForWhiteLayingHensProgram,
			description: lang.EggPercentageForWhiteLayingHensProgram,
			imgSrc: icon7,
			link: "egg-percent-age-for-white-laying-hens-program",
		},
		{
			id: 8,
			title: lang.CumulativeEggProductionForWhiteLayingHensProgram,
			description: lang.CumulativeEggProductionForWhiteLayingHensProgram,
			imgSrc: icon8,
			link: "cumulative-egg-production-for-white-laying-hens-program",
		},
	];
	return (
		<MainFourServisesLayout
			serviceData={whiteLayingHensManagmentProgramsServices}
			title={lang.title}
			isShowSuggestedServices={false}
		/>
	);
}

export default WhiteLayingHensManagmentPrograms;
