import React, { useState } from "react";
import { useSelector } from "react-redux";
import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import InputField from "../../../../components/Calculations/InputField";
import useEnterKeyPress from "../../../../utils/EnterCalculateBtn";

const EuropeanProductionEfficiencyFactorProgram = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [deadBirds, setDeadBirds] = useState("");
	const [totalFeedIntake, setTotalFeedIntake] = useState("");
	const [averageBodyWeight, setAverageBodyWeight] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");

	const calculateResults = () => {
		const birds = parseFloat(numberOfBirds);
		const dead = parseFloat(deadBirds);
		const feedIntake = parseFloat(totalFeedIntake);
		const bodyWeight = parseFloat(averageBodyWeight);
		const age = parseFloat(ageOfBirds);

		if (
			isNaN(birds) ||
			isNaN(dead) ||
			isNaN(feedIntake) ||
			isNaN(bodyWeight) ||
			isNaN(age)
		) {
			setResult("Please enter valid numbers in all fields.");
			return;
		}

		const fcr = feedIntake / (birds - dead) / bodyWeight;
		const mortalityRate = (dead / birds) * 100;
		const livability = 100 - mortalityRate;
		const eec = ((bodyWeight * livability) / (fcr * age)) * 100;

		setResult(`European Efficiency Coefficient = ${eec.toFixed(0)}`);
	};

	useEnterKeyPress(calculateResults);
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>{lang.factorCalculator}</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={lang.totalNumberOfBirds}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder='Enter number of birds'
					/>
					<InputField
						label={lang.totalDeadBirds}
						value={deadBirds}
						onChange={(e) => setDeadBirds(e.target.value)}
						placeholder='Enter number of dead birds'
					/>
					<InputField
						label={lang.totalFeedIntake}
						value={totalFeedIntake}
						onChange={(e) => setTotalFeedIntake(e.target.value)}
						placeholder='Enter total feed intake'
					/>
					<InputField
						label={lang.averageBodyWeight}
						value={averageBodyWeight}
						onChange={(e) => setAverageBodyWeight(e.target.value)}
						placeholder='Enter average body weight'
					/>
					<InputField
						label={lang.ageOfBirds}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder='Enter age of birds'
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button onClick={calculateResults} className='btn appointment-btn'>
							{lang.calculate}
						</button>
					</div>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
};

export default EuropeanProductionEfficiencyFactorProgram;
