import React from "react";
import "./ThemeIcon.scss";

const ThemeIcon = (icon) => {
	return (
		<div className='icon-box' style={{ width: "100px" }}>
			<img src={icon.icon} alt='icon' />
		</div>
	);
};

export default ThemeIcon;
