import React from "react";
import Register from "../sections/Register/Register";

function RegisterPage() {
	return (
		<>
			<Register />
		</>
	);
}

export default RegisterPage;
