import React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import "./Blogs.scss";
import BlogCard from "../../components/BlogCard/BlogCard";
import BlogData from "./BlogData";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
import { useSelector } from "react-redux";

const Blogs = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en" ? en.blogs : language === "ar" ? ar.blogs : fr.blogs;

	return (
		<section
			className='pt-100 pb-70 blog-section'
			data-aos='fade-up'
			data-aos-duration='2000'
		>
			<div className='container'>
				<SectionTitle subTitle={lang.blogs} title={lang.blogs_welcome} />

				<div className='row'>
					{BlogData.map((blog) => (
						<BlogCard blog={blog} />
					))}
				</div>
			</div>
		</section>
	);
};

export default Blogs;
