import { useEffect } from "react";

function useEnterKeyPress(callback) {
	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === "Enter") {
				event.preventDefault();
				if (callback) {
					callback();
				}
			}
		};

		window.addEventListener("keydown", handleKeyPress);

		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [callback]);
}

export default useEnterKeyPress;
