// store.js
import { configureStore } from "@reduxjs/toolkit";
import langReducer from "./slices/langSlice"; // Adjust the path to where your langSlice is located
import drugInteractionsSlice from "./slices/drugInteractionsSlice";

const store = configureStore({
	reducer: {
		lang: langReducer,
		drugInteractions: drugInteractionsSlice,
	},
});

export default store;
