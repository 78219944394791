import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
import icon from "../../../../../assets/icons//Water Consumption Rate For Brown pullets Programs.webp"; // Updated icon
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";

function WaterConsumptionRateForBrownpulletsPrograms() {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");
	const [weeks, setWeeks] = useState(""); // Added state for weeks

	// Water consumption array (equivalent to PHP $water)
	const water = [
		0, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
		39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 50, 52, 54, 56, 58, 60, 62, 63, 64,
		65, 66, 67, 68, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84,
		85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102,
		103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 114, 115, 115, 116, 118,
		120, 120, 121, 122, 122, 123, 124, 124, 125, 125, 126, 126, 127, 127, 128,
		128, 129, 129, 130, 130, 131, 131, 132, 132, 133, 134, 135, 135, 136, 136,
		137, 137, 138, 138, 139, 140,
	];

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const age = parseFloat(ageOfBirds);

		if (!isNaN(numBirds) && !isNaN(age) && numBirds >= 0 && age >= 0) {
			if (age > 126) {
				alert(newLang.BirdAgeExceedsLimit); // Assuming `BirdAgeExceedsLimit` is a localized message
				return; // Stop further calculation if the age exceeds 126
			}

			// Calculate the week based on the bird's age
			const weeksCalculated = Math.ceil(age / 7);
			setWeeks(weeksCalculated);

			// Water consumption logic based on age and number of birds
			const waterConsumption = (water[age] * numBirds) / 1000;
			const roundedWaterConsumption = Math.round(waterConsumption);

			// Update result with water consumption and weeksCalculated directly
			setResult(
				`${newLang.NormalWaterConsumption} = ${roundedWaterConsumption} ${lang.Liter} \n${weeksCalculated} ${newLang.weeks}`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.brownPulletsManagmentPrograms
						.WaterConsumptionRateForBrownpulletsPrograms
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={icon}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
}

export default WaterConsumptionRateForBrownpulletsPrograms;
