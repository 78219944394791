import React from "react";
import MainVideos from "../sections/Videos/Videos";

function Videos() {
	return (
		<>
			<MainVideos />
		</>
	);
}

export default Videos;
