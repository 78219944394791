// slices/langSlice.js
"use client";

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	language: "en" || "ar" || "fr",
};

export const langSlice = createSlice({
	name: "language",
	initialState,
	reducers: {
		// toggleLanguage: (state) => {
		// 	state.enLanguage = !state.enLanguage;
		// },
		setLanguage: (state, action) => {
			state.language = action.payload;
			console.log("action", action);
		},
	},
});

export const { toggleLanguage, setLanguage } = langSlice.actions;

export default langSlice.reducer;
