import imgOne from "../../assets/blogs/1.png";
import imgTwo from "../../assets/blogs/2.png";
import imgThree from "../../assets/blogs/3.png";

const BlogData = [
	{
		img: imgOne,
		url: "new-technology-make-for-dental-operation",
		title: "New Technology Make for Dental Operation",
		description:
			"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ",
		category: "TECHNOLOGY",
	},
	{
		img: imgTwo,
		url: "regular-dental-care-make-your-smile-brighter",
		title: "Regular Dental care make Your Smile Brighter",
		description:
			"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ",
		category: "DENTAL",
	},
	{
		img: imgThree,
		url: "dental-hygiene-for-all-age-to-make-smile",
		title: "Dental Hygiene for All Age to Make Smile",
		description:
			"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing ",
		category: "HYGIENE",
	},
];

export default BlogData;
