import React from "react";
import "./ServiceBox.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";

function ServiceBox({ imgSrc, title, description, link, number }) {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;
	return (
		<div className='service-item'>
			<div>
				<span>{number}</span>
				<Link to={link}>
					<img src={imgSrc} alt={title} width={80} height={80} />
				</Link>
			</div>
			<Link className='title-link' to={link}>
				{title}
			</Link>
			{/* <p>{description}</p> */}
			<div className='theme-btn'>
				<Link to={link}>{lang.try_it_now}</Link>
			</div>
		</div>
	);
}

export default ServiceBox;
