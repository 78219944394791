import React from "react";

function MyAccount() {
	return (
		<>
			<div>MyAccount</div>
		</>
	);
}

export default MyAccount;
