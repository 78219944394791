import React from "react";
import "./Gallery.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

const Gallery = ({ lang }) => {
	const serviceLang = lang.services;
	return (
		<section
			className='gallery-section pt-100 pb-70'
			data-aos='fade-up'
			data-aos-duration='2000'
		>
			<div className='container'>
				<SectionTitle
					subTitle='Gallery'
					title={serviceLang.explor}
					description={serviceLang.explor_description}
				/>
				<div className='row'>
					<div className='col-md-5 col-sm-6'>
						<div className='gallery-img'>
							<img src={"/imgs/chicken.jpg"} alt='gallery' />
						</div>
					</div>
					<div className='col-md-3 col-sm-6'>
						<div className='gallery-img'>
							<img src='/imgs/eggs.jpg' alt='gallery' />
						</div>
					</div>
					<div className='col-md-4 col-sm-6'>
						<div className='gallery-img'>
							<img src={"/imgs/hourse2.jpg"} alt='gallery' />
						</div>
					</div>
					<div className='col-md-3 col-sm-6'>
						<div className='gallery-img'>
							<img src={"/imgs/hourse.jpg"} alt='gallery' />
						</div>
					</div>
					<div className='col-md-4 col-sm-6'>
						<div className='gallery-img'>
							<img src={"/imgs/bits.jpg"} alt='gallery' />
						</div>
					</div>
					<div className='col-md-5 col-sm-6'>
						<div className='gallery-img'>
							<img src={"/imgs/fly.jpg"} alt='gallery' />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Gallery;
