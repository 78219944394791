import React, { useState } from "react";
import { useSelector } from "react-redux";
import useEnterKeyPress from "../../../../utils/EnterCalculateBtn";
import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import icon from "../../../../assets/icons/Feed Convertion Ratio FCR Program.webp"; // Add appropriate icon path

const ST_FCR = [
	0, 0.27, 0.44, 0.58, 0.69, 0.78, 0.86, 0.93, 0.98, 1.02, 1.06, 1.09, 1.12,
	1.14, 1.16, 1.17, 1.19, 1.21, 1.22, 1.23, 1.25, 1.26, 1.27, 1.29, 1.3, 1.32,
	1.34, 1.36, 1.38, 1.4, 1.42, 1.45, 1.47, 1.49, 1.52, 1.54, 1.56, 1.59, 1.61,
	1.63, 1.66, 1.68, 1.7, 1.72, 1.74, 1.76, 1.78, 1.79, 1.81, 1.83, 1.85, 1.86,
	1.88, 1.89, 1.91, 1.93, 1.94, 1.96, 1.98, 1.99, 2.01,
];

const FeedConvertionRatioFCRProgram = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [totalFeedIntake, setTotalFeedIntake] = useState("");
	const [averageBodyWeight, setAverageBodyWeight] = useState("");
	const [result, setResult] = useState("");

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const age = parseInt(ageOfBirds, 10);
		const feedIntake = parseFloat(totalFeedIntake);
		const bodyWeight = parseFloat(averageBodyWeight);

		if (
			!isNaN(numBirds) &&
			!isNaN(age) &&
			!isNaN(feedIntake) &&
			!isNaN(bodyWeight) &&
			age >= 0 &&
			age < ST_FCR.length
		) {
			const res0 = feedIntake / numBirds;
			const res1 = res0 / bodyWeight;

			const x = ST_FCR[age];
			const diff = res1 - x;

			let resultText = `${lang.normalFCR} = ${x.toFixed(2)}\n\n${
				lang.yourFCR
			} = ${res1.toFixed(2)}`;

			if (diff === 0) resultText += `\n${lang.veryGood}`;
			else if (diff < 0) resultText += `\n${lang.excellent}`;
			else if (diff >= 0.1 && diff <= 0.2) resultText += `\n${lang.good}`;
			else resultText += `\n${lang.poor}`;

			setResult(resultText);
		} else {
			setResult(lang.invalidValues);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{lang.FeedConvertionRatioFCRProgram}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<div className='form-group'>
						<label>{lang.numberOfBirds}:</label>
						<input
							type='number'
							value={numberOfBirds}
							onChange={(e) => setNumberOfBirds(e.target.value)}
							className='form-control'
							placeholder={lang.enterNumberOfBirds}
						/>
					</div>
					<div className='form-group'>
						<label>{lang.ageOfBirds}:</label>
						<input
							type='number'
							value={ageOfBirds}
							onChange={(e) => setAgeOfBirds(e.target.value)}
							className='form-control'
							placeholder={lang.enterAgeOfBirds}
						/>
					</div>
					<div className='form-group'>
						<label>{lang.totalFeedIntake}:</label>
						<input
							type='number'
							value={totalFeedIntake}
							onChange={(e) => setTotalFeedIntake(e.target.value)}
							className='form-control'
							placeholder={lang.enterTotalFeedIntake}
						/>
					</div>
					<div className='form-group'>
						<label>{lang.averageBodyWeight}:</label>
						<input
							type='number'
							value={averageBodyWeight}
							onChange={(e) => setAverageBodyWeight(e.target.value)}
							className='form-control'
							placeholder={lang.enterAverageBodyWeight}
						/>
					</div>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={icon}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<div className='form-group'>
				<textarea
					value={result}
					readOnly
					style={{ width: "100%", height: "100px", marginTop: "20px" }}
					className='result'
				/>
			</div>
		</section>
	);
};

export default FeedConvertionRatioFCRProgram;
