import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../sections/Footer/Footer";

function MainLayout({ children }) {
	return (
		<>
			<main>
				<Navbar />
				{children}
				<Footer></Footer>
			</main>
		</>
	);
}

export default MainLayout;
