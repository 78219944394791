import React, { useState } from "react";
import { useSelector } from "react-redux";
import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import icon from "../../../../assets/icons/Production Guide For Brown Laying Hens.webp"; // Updated icon
import useEnterKeyPress from "../../../../utils/EnterCalculateBtn";
import InputField from "../../../../components/Calculations/InputField"; // Reused InputField component

const ProductionGuideProgram = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [deadBirds, setDeadBirds] = useState("");
	const [totalFeedIntake, setTotalFeedIntake] = useState("");
	const [averageBodyWeight, setAverageBodyWeight] = useState("");
	const [result, setResult] = useState("");

	// Fetch language setting from Redux store
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	// Handle form submission and calculations
	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const deadBirdsCount = parseFloat(deadBirds);
		const feedIntake = parseFloat(totalFeedIntake);
		const avgBodyWeight = parseFloat(averageBodyWeight);

		if (
			!isNaN(numBirds) &&
			!isNaN(deadBirdsCount) &&
			!isNaN(feedIntake) &&
			!isNaN(avgBodyWeight)
		) {
			const fcr = feedIntake / (numBirds - deadBirdsCount) / avgBodyWeight;
			const productionGuide = (avgBodyWeight / fcr) * 100;
			setResult(
				`${lang.ProductionGuide} = ${productionGuide.toFixed(2)} ${
					lang.Percentage
				}`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	// Trigger calculations on Enter key press
	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>{lang.ProductionGuideCalculator}</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.DeadBirds}:`}
						value={deadBirds}
						onChange={(e) => setDeadBirds(e.target.value)}
						placeholder={lang.EnterDeadBirds}
					/>
					<InputField
						label={`${lang.TotalFeedIntake}:`}
						value={totalFeedIntake}
						onChange={(e) => setTotalFeedIntake(e.target.value)}
						placeholder={lang.EnterTotalFeedIntake}
					/>
					<InputField
						label={`${lang.AverageBodyWeight}:`}
						value={averageBodyWeight}
						onChange={(e) => setAverageBodyWeight(e.target.value)}
						placeholder={lang.EnterAverageBodyWeight}
					/>
					<button
						onClick={calculateResults}
						className='btn appointment-btn'
					>
						{lang.Calculate}
					</button>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={icon}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
};

export default ProductionGuideProgram;
