import React, { useState } from "react";
import { useSelector } from "react-redux";
import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import InputField from "../../../../components/Calculations/InputField";
import icon from "../../../../assets/icons/Normal Mortality Rate Program.webp"; // Update the path if needed

const NormalMortalityRateProgram = () => {
	// State variables for the input values and result
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");

	// Handle the calculation logic when the button is clicked
	const handleCalculate = () => {
		// Convert input values to numbers
		const birds = parseFloat(numberOfBirds);
		const age = parseFloat(ageOfBirds);

		// Ensure the input values are valid numbers
		if (isNaN(birds) || isNaN(age)) {
			setResult("Please enter valid numbers.");
			return;
		}

		// Calculate normal daily mortalities and total mortalities
		let dailyMortalities = birds * 0.001;
		dailyMortalities = Math.round(dailyMortalities); // Round to integer
		const totalMortalities = dailyMortalities * age;

		// Update the result with both values
		setResult(
			`${lang.NormalDailyMortalities} = ${dailyMortalities}\n ${lang.NormalTotalMortalities} = ${totalMortalities}`
		);
	};

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{lang.NormalMortalityRateProgram}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.numberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.Enterageofbirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={handleCalculate}
							className='btn appointment-btn'
						>
							{lang.calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={icon}
						alt={lang.iconAltText}
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
};

export default NormalMortalityRateProgram;
