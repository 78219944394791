import React from "react";
import MainFourServisesLayout from "../../../../components/MainFourServisesLayout/MainFourServisesLayout";
import icon1 from "../../../../assets/icons/Average Egg Weight For White Laying Hens Program.webp"; //not
import icon2 from "../../../../assets/icons/Water Consumption Rate Program.webp";
import icon3 from "../../../../assets/icons/Feed Consumption Rate Program.webp";
import icon4 from "../../../../assets/icons/Total Feed Consumption For White Laying Hens Program.webp"; //not
import icon5 from "../../../../assets/icons/Feed Convertion Ratio FCR Program.webp";
import icon6 from "../../../../assets/icons/Normal Body Weight For White Pullets Program.webp";

import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import { useSelector } from "react-redux";
function WhitePulletsManagmentPrograms() {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.layersManagment.whitePulletsManagmentPrograms
			: language === "ar"
			? ar.services.layersManagment.whitePulletsManagmentPrograms
			: fr.services.layersManagment.whitePulletsManagmentPrograms;

	const whitePulletsManagmentProgramsServices = [
		{
			id: 1,
			title: lang.RearingGuideforWhitePulletsProgram, // not yet
			description: lang.RearingGuideforWhitePulletsProgram,
			imgSrc: icon1,
			link: "rearing-guide-for-white-pullets-program",
		},
		{
			id: 2,
			title: lang.WaterConsumptionRateForWhitepulletsPrograms,
			description: lang.WaterConsumptionRateForWhitepulletsPrograms,
			imgSrc: icon2,
			link: "water-consumption-rate-for-white-pullets-programs",
		},
		{
			id: 3,
			title: lang.FeedConsumptionRateForWhitepulletsPrograms,
			description: lang.FeedConsumptionRateForWhitepulletsPrograms,
			imgSrc: icon3,
			link: "feed-consumption-rate-for-white-pullets-programs",
		},
		{
			id: 4,
			title: lang.TotalFeedConsumptionForWhitePulletsProgram,
			description: lang.TotalFeedConsumptionForWhitePulletsProgram,
			imgSrc: icon4,
			link: "total-feed-consumption-for-white-pullets-program",
		},
		{
			id: 5,
			title: lang.FeedConvertionRatioFCRForWhitePulletsProgram,
			description: lang.FeedConvertionRatioFCRForWhitePulletsProgram,
			imgSrc: icon5,
			link: "feed-convertion-ratio-FCR-for-white-pullets-program",
		},
		{
			id: 6,
			title: lang.NormalBodyWeightForWhitePulletsProgram,
			description: lang.NormalBodyWeightForWhitePulletsProgram,
			imgSrc: icon6,
			link: "normal-body-weight-for-white-pullets-program",
		},
	];
	return (
		<MainFourServisesLayout
			serviceData={whitePulletsManagmentProgramsServices}
			title={lang.title}
			isShowSuggestedServices={false}
		/>
	);
}

export default WhitePulletsManagmentPrograms;
