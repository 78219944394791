import React, { useState } from "react";
import useEnterKeyPress from "../../../../utils/EnterCalculateBtn";
import icon from "../../../../assets/icons/Feed Consumption Rate Program.webp"; // Use appropriate icon
import InputField from "../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../lang/ar/ar.json";
import en from "../../../../lang/en/en.json";
import fr from "../../../../lang/fr/fr.json";
import { Link } from "react-router-dom";

// Define the FEED_STD_ARRAY array in your component
const FEED_STD_ARRAY = [
	0, 13, 15, 18, 21, 24, 27, 31, 36, 40.5, 45, 49.5, 54, 58.5, 63, 67.5, 72,
	76.5, 81, 85.5, 90, 94.5, 99, 103.5, 108, 112.5, 117, 121.5, 126, 130.5, 135,
	139.5, 144, 148.5, 153, 157.5, 162, 166.5, 171, 175.5, 180, 184.5, 189,
];

const FeedConsumptionRateProgram = () => {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");

	// Function to handle the calculation
	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const age = parseInt(ageOfBirds, 10);

		if (
			!isNaN(numBirds) &&
			!isNaN(age) &&
			age >= 0 &&
			age < FEED_STD_ARRAY.length
		) {
			const feedStandard = FEED_STD_ARRAY[age];
			const res1 = (numBirds * feedStandard) / 1000;
			const roundedResult = Math.round(res1);

			setResult(`${lang.Daily_feed_intake} = ${roundedResult} ${lang.Kg}`);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>{lang.Feed_Consumption_Rate}</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={lang.NumberofBirds}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.Enternumberofbirds}
					/>
					<InputField
						label={`${lang.AgeofBirds} ${lang.age}`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.Enterageofbirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={icon}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
};

export default FeedConsumptionRateProgram;
