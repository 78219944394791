import React from "react";
import Gallery from "../sections/Gallery/Gallery";
import ServiceBanner from "../sections/ServiceBanner/ServiceBanner";
import Symptoms from "../sections/Symptoms/Symptoms";
import Appointment from "../sections/Appointment/Appointment";
import { Outlet } from "react-router-dom";
import ar from "../lang/ar/ar.json";
import en from "../lang/en/en.json";
import fr from "../lang/fr/fr.json";
import { useSelector } from "react-redux";

const Services = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang = language === "en" ? en : language === "ar" ? ar : fr;

	console.log("first", lang);
	return (
		<div dir={language === "ar" && "rtl"}>
			<ServiceBanner lang={lang} />
			<Symptoms lang={lang} />
			<Gallery lang={lang} />
			<Appointment lang={lang} />
			<Outlet />
		</div>
	);
};

export default Services;
