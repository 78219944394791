import React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import "./Services.scss";
import ServicesData from "./ServiceData";
import Service from "../../components/Service/Service";
import { Link } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
import { useSelector } from "react-redux";
import { useServicesData } from "./AllServices";
const Services = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en" ? en.hero : language === "ar" ? ar.hero : fr.hero;

	const servicesData = useServicesData();
	// const { title, description, icon } = serviceList;
	return (
		<section
			className='service-section pt-100 pb-70'
			data-aos='fade-up'
			data-aos-duration='2000'
		>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-6 col-sm-6'>
						<SectionTitle title={lang.experience_title} subTitle='Services' />
					</div>
					<div className='col-lg-6 col-sm-6'>
						<p className='service-title-text'>{lang.experience_description}</p>
					</div>
				</div>

				<div className='row'>
					{servicesData.map((singleService) => (
						<Service serviceList={singleService} />
					))}
				</div>
			</div>

			<div className='services-link text-center'>
				<Link to='/services' dir={language === "ar" && "ltr"}>
					{lang.View_all_service_list}
					<BsFillArrowRightCircleFill />
				</Link>
			</div>
		</section>
	);
};

export default Services;
