import React from "react";
import logo from "../../assets/logo.png";
import "./Footer.scss";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import call from "../../assets/footer/calling.png";
import time from "../../assets/footer/time.png";
import location from "../../assets/footer/location.png";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
import { useSelector } from "react-redux";
import ChangeLang from "../../utils/ChangeLang";

const Footer = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en" ? en.footer : language === "ar" ? ar.footer : fr.footer;
	let navlang =
		language === "en" ? en.navbar : language === "ar" ? ar.navbar : fr.navbar;
	const footerMenu = [
		{
			name: navlang.about,
			link: "/about",
		},
		{
			name: navlang.services,
			link: "/services",
		},
		{
			name: navlang.blogs,
			link: "/blogs",
		},
		{
			name: navlang.jobs,
			link: "/jobs",
		},
		{
			name: navlang.vedios,
			link: "/videos",
		},
		{
			name: navlang.myAccount,
			link: "/my-account",
		},
		{
			name: navlang.contact,
			link: "/contact",
		},
	];

	const footerContacts = [
		{
			title: lang.Phone_Number,
			info: "+088 123 654 987",
			icon: call,
		},
		{
			title: lang.Open_Hour,
			info: "09:00 AM - 18:00 PM",
			icon: time,
		},
		{
			title: lang.Clinic_Address,
			info: lang.addresses,
			icon: location,
		},
	];

	return (
		<footer dir={language === "ar" && "rtl"} className='pt-100 pb-70'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-6 col-md-5'>
						<div className='footer-logo'>
							<img src={logo} alt='logo' width={100} height={100} />
						</div>
						<p>{lang.phrase}</p>

						<div className='social-logo'>
							<p>{lang.follow_us}</p>
							<ul>
								<li>
									<a href='/'>
										<FaFacebookF />
									</a>
								</li>
								<li>
									<a href='/'>
										<FaTwitter />
									</a>
								</li>
								<li>
									<a href='/'>
										<FaInstagram />
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className='col-lg-2 col-md-2'>
						<div className='footer-link'>
							<p>{lang.Quick_Links}</p>
							<ul>
								{footerMenu.map((singleMenu) => (
									<li>
										<Link to={singleMenu.link}>{singleMenu.name}</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className='col-lg-4 col-md-5'>
						<div className='footer-contact'>
							<p>{lang.Contact_Information}</p>

							{footerContacts.map((footerContact) => {
								return (
									<div className='contact-list'>
										<div className='contact-icon'>
											<img src={footerContact.icon} alt='call' />
										</div>
										<div className='contact-text'>
											<p>{footerContact.title}</p>
											<h5>{footerContact.info}</h5>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div className='copyright-area'>
					<div className='copy-text'>
						<p>&copy; {lang.rights}</p>
					</div>
					<div className='copy-links'>
						<ul>
							<li>
								<Link to='/'>{lang.Terms}</Link>
							</li>
							<li>
								<Link to='/'>{lang.Privacy_Policy}</Link>
							</li>
							<li>
								<ChangeLang en={"en"} ar={"ar"} fr={"fr"} />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
