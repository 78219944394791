import React, { useState } from "react";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";
import InputField from "../../../../../components/Calculations/InputField";
import icon from "../../../../../assets/icons/Average Egg Weight For White Laying Hens Program.webp";
// Example egg weight array (replace with actual data if needed)
const eggWeight = [
	40, 40, 41, 42, 42, 43, 43, 44, 44, 45, 45, 45, 46, 46, 46.5, 46.5, 46.5,
	46.5, 46.5, 46.5, 46.5, 46.5, 47, 47, 47.5, 47.5, 48, 48, 48.5, 48.8, 49, 49,
	49.5, 49.5, 50, 50, 50.5, 50.5, 51, 51, 51.5, 51.5, 52, 52, 52.5, 52.5, 53,
	53, 53.5, 53.5, 54, 54, 54.5, 54.5, 55, 55, 55.5, 55.5, 56, 56, 56.5, 56.5,
	57, 57, 57.1, 57.2, 57.3, 57.4, 57.5, 57.6, 57.6, 57.7, 57.8, 57.8, 57.9,
	57.9, 58, 58, 58.1, 58.2, 58.3, 58.4, 58.5, 58.6, 58.7, 58.8, 58.9, 59, 59.1,
	59.2, 59.3, 59.3, 59.4, 59.4, 59.5, 59.5, 59.6, 59.6, 59.6, 59.6, 59.6, 59.7,
	59.7, 59.7, 59.7, 59.7, 59.7, 59.8, 59.8, 59.8, 59.8, 59.8, 59.9, 59.9, 59.9,
	59.9, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60.1, 60.1, 60.1, 60.2, 60.2,
	60.2, 60.3, 60.3, 60.3, 60.4, 60.4, 60.4, 60.5, 60.5, 60.6, 60.6, 60.6, 60.6,
	60.7, 60.7, 60.7, 60.8, 60.8, 60.9, 60.9, 61, 61, 61, 61, 61.1, 61.1, 61.2,
	61.2, 61.2, 61.3, 61.3, 61.3, 61.3, 61.3, 61.4, 61.4, 61.4, 61.4, 61.4, 61.4,
	61.4, 61.5, 61.5, 61.5, 61.5, 61.5, 61.5, 61.5, 61.6, 61.6, 61.6, 61.6, 61.7,
	61.7, 61.7, 61.8, 61.8, 61.8, 61.8, 61, 61.1, 61.2, 61.3, 61.4, 61.5, 61.6,
	61.7, 61.8, 61.9, 62, 62.1, 62.2, 62.2, 62.3, 62.3, 62.3, 62.3, 62.4, 62.4,
	62.4, 62.5, 62.5, 62.5, 62.5, 62.5, 62.5, 62.5, 62.5, 62.5, 62.5, 62.5, 62.5,
	62.5, 62.5, 62.5, 62.5, 62.5, 62.5, 62.5, 62.5, 62.6, 62.6, 62.6, 62.6, 62.6,
	62.6, 62.6, 62.6, 62.6, 62.6, 62.6, 62.6, 62.6, 62.6, 62.7, 62.7, 62.7, 62.7,
	62.7, 62.7, 62.7, 62.8, 62.8, 62.8, 62.8, 62.8, 62.8, 62.8, 62.8, 62.8, 62.8,
	62.8, 62.8, 62.8, 62.8, 62.8, 62.8, 62.8, 62.8, 62.8, 62.8, 62.8, 62.9, 62.9,
	62.9, 62.9, 62.9, 62.9, 62.9, 63, 63, 63, 63, 63, 63, 63, 63, 63, 63, 63, 63,
	63, 63, 63.1, 63.1, 63.1, 63.1, 63.1, 63.1, 63.1, 63.2, 63.2, 63.2, 63.2,
	63.2, 63.2, 63.2, 63.2, 63.2, 63.2, 63.2, 63.2, 63.2, 63.2, 63.2, 63.2, 63.2,
	63.2, 63.2, 63.2, 63.2, 63.3, 63.3, 63.3, 63.3, 63.3, 63.3, 63.3, 63.3, 63.3,
	63.3, 63.3, 63.3, 63.3, 63.3, 63.4, 63.4, 63.4, 63.4, 63.4, 63.4, 63.4, 63.4,
	63.4, 63.4, 63.4, 63.4, 63.4, 63.4, 63.4, 63.4, 63.4, 63.4, 63.4, 63.4, 63.4,
	63.4, 63.4, 63.4, 63.4, 63.4, 63.4, 63.4, 63.5, 63.5, 63.5, 63.5, 63.5, 63.5,
	63.5, 63.5, 63.5, 63.5, 63.5, 63.5, 63.5, 63.5, 63.5, 63.5, 63.5, 63.5, 63.5,
	63.5, 63.5, 63.5, 63.5, 63.5, 63.5, 63.5, 63.5, 63.5, 63.6, 63.6, 63.6, 63.6,
	63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.6,
	63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.6, 63.7, 63.7,
	63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7,
	63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7,
	63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7,
	63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7,
	63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7,
	63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.7, 63.8, 63.8, 63.8,
	63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8,
	63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8,
	63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8, 63.8,
];

const AverageEggWeightForWhiteLayingHensProgram = () => {
	const [age, setAge] = useState("");
	const [weeks, setWeeks] = useState("");
	const [result, setResult] = useState("");

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const ageValue = parseFloat(age);

		if (ageValue < 120 || ageValue > 658) {
			alert(newLang.minAndMaxAge);
			setResult("");
			return;
		}

		const weeksCalculated = Math.ceil(ageValue / 7);
		setWeeks(weeksCalculated);

		const index = ageValue - 120;
		const eggWeightValue = eggWeight[index] || 0; // Handle case if age is out of range

		const res1 = eggWeightValue; // In grams
		const res2 = (res1 * 30) / 1000; // Convert to Kg for 30 eggs

		setResult(
			`${newLang.Normaleggweight} = ${res1} ${newLang.gram}.\n\n${
				newLang.EggTray
			} (30 ${newLang.Eggs}) ${newLang.weight} = ${res2.toFixed(2)} ${
				newLang.Kg
			} \n\n${weeks} ${newLang.weeks}`
		);
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.whiteLayingHensManagmentPrograms
						.AverageEggWeightForWhiteLayingHensProgram
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={age}
						onChange={(e) => setAge(e.target.value)}
						placeholder={newLang.Enterageindays}
					/>

					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={icon}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
};

export default AverageEggWeightForWhiteLayingHensProgram;
