// servicesData.js
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
import { useSelector } from "react-redux";
import iconTwo from "../../assets/icons/Layers Managment Programs.webp";
import iconOne from "../../assets/icons/Broilers Managment Programs.webp";
import iconThree from "../../assets/icons/Drug Interactions Program.webp";
import iconFour from "../../assets/icons/Feed Analysis Program.webp";
export const useServicesData = () => {
	const language = useSelector((state) => state?.lang?.language);
	const lang =
		language === "en"
			? en.services
			: language === "ar"
			? ar.services
			: fr.services;

	return [
		{
			id: 1,
			icon: iconOne,
			title: lang.Broilers_Managment,
			description: lang.Broilers_Managment_description,
			url: "/services/broilers-management",
		},
		{
			id: 2,
			icon: iconTwo,
			title: lang.Layers_Managment,
			description: lang.Layers_Managment_description,
			url: "/services/layers-management",
		},
		{
			id: 3,
			icon: iconThree,
			title: lang.Drug_Interactions,
			description: lang.Drug_Interactions_description,
			url: "/services/drug-interactions",
		},
		{
			id: 4,
			icon: iconFour,
			title: lang.feed_Analysis,
			description: lang.feed_Analysis_description,
			url: "/services/feed-analysis",
		},
	];
};
