import React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import "./Contactus.scss";
import ContactForm from "../../components/ContactForm/ContactForm";

import { useSelector } from "react-redux";
import ar from "../../lang/ar/ar.json";
import en from "../../lang/en/en.json";
import fr from "../../lang/fr/fr.json";
const Contactus = () => {
	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.contact
			: language === "ar"
			? ar.contact
			: fr.contact;
	return (
		<>
			<section className='section-bg section-common contact-section'>
				<SectionTitle title={lang.contact_us} description={lang.intro} />
			</section>
			<section
				className='contact-form-area'
				data-aos='fade-up'
				data-aos-duration='2000'
			>
				<ContactForm />
			</section>
		</>
	);
};

export default Contactus;
