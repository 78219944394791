import React from "react";
import AboutBanner from "../sections/AboutBanner/AboutBanner";
import Faq from "../sections/Faq/Faq";
import Priority from "../sections/Priority/Priority";
import Safety from "../sections/Safety/Safety";
import Team from "../sections/Team/Team";

const About = () => {
	return (
		<>
			<AboutBanner />
			<Priority />
			<Safety />
			<Team />
			<Faq />
		</>
	);
};

export default About;
