import React from "react";
import { Link } from "react-router-dom";
import "./ServiceBanner.scss";

const ServiceBanner = ({ lang }) => {
	const servicesText = lang.services;
	console.log("servicesText", servicesText);
	return (
		<section className='service-banner-section section-common section-bg'>
			<div className='d-table'>
				<div className='d-table-cell'>
					<div className='container'>
						<div className='row align-items-center'>
							<div className='col-lg-5 col-md-6'>
								<div
									className='service-banner-text'
									data-aos='fade-up'
									data-aos-duration='2000'
								>
									<h2>{servicesText.our_services}</h2>
									<p>
										{servicesText.Broilers_Managment_description} <br />
										{servicesText.Layers_Managment_description}
									</p>
									<div className='theme-btn'>
										<Link to='/'>{servicesText.book_now}</Link>
									</div>
								</div>
							</div>
							<div className='col-lg-7 col-md-6'>
								<div
									className='service-banner-img'
									data-aos='fade-up'
									data-aos-duration='2000'
								>
									<img src={"/imgs/chicken2.jpg"} alt='service' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ServiceBanner;
