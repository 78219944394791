import React from "react";
import Login from "../sections/Login/Login";

function LoginPage() {
	return (
		<>
			<Login />
		</>
	);
}

export default LoginPage;
