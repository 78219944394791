import React, { useState } from "react";
import InputField from "../../../../../components/Calculations/InputField";
import { useSelector } from "react-redux";
import ar from "../../../../../lang/ar/ar.json";
import en from "../../../../../lang/en/en.json";
import fr from "../../../../../lang/fr/fr.json";
import icon from "../../../../../assets/icons/Feed Consumption Rate For Brown pullets Programs.webp"; // Updated icon
import useEnterKeyPress from "../../../../../utils/EnterCalculateBtn";

function FeedConsumptionRateForBrownpulletsPrograms() {
	const [numberOfBirds, setNumberOfBirds] = useState("");
	const [ageOfBirds, setAgeOfBirds] = useState("");
	const [result, setResult] = useState("");
	const [weeks, setWeeks] = useState("");

	// Feed standard array (equivalent to PHP $FEDD_STD_ARRAY)
	const DFI = [
		0, 14, 14, 14, 15, 15, 15, 16, 16, 17, 18, 19, 20, 20, 21, 21, 21, 22, 22,
		22, 23, 23, 24, 24, 25, 25, 26, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37,
		38, 39, 39, 40, 40, 40, 40, 40, 41, 41, 41, 42, 43, 44, 45, 45, 46, 47, 47,
		48, 49, 50, 51, 52, 53, 54, 54, 54, 55, 55, 55, 56, 56, 57, 58, 59, 60, 61,
		62, 63, 63, 63, 64, 64, 65, 65, 66, 66, 67, 68, 69, 70, 71, 72, 72, 72, 72,
		73, 73, 73, 74, 74, 74, 75, 75, 75, 76, 76, 76, 76, 76, 77, 77, 77, 78, 79,
		80, 80, 81, 81, 82, 82, 83, 84, 85, 86, 87, 88, 88,
	];

	const language = useSelector((state) => state?.lang?.language);
	let lang =
		language === "en"
			? en.services.broilers
			: language === "ar"
			? ar.services.broilers
			: fr.services.broilers;

	let newLang =
		language === "en"
			? en.services.layersManagment
			: language === "ar"
			? ar.services.layersManagment
			: fr.services.layersManagment;

	const calculateResults = () => {
		const numBirds = parseFloat(numberOfBirds);
		const age = parseFloat(ageOfBirds);

		if (!isNaN(numBirds) && !isNaN(age) && numBirds >= 0 && age >= 0) {
			// Check if the age exceeds 126
			if (age > 126) {
				alert(newLang.BirdAgeExceedsLimit); // Assuming `BirdAgeExceedsLimit` is a localized message
				return;
			}

			// Calculate the week based on the bird's age
			const weeksCalculated = Math.ceil(age / 7);
			setWeeks(weeksCalculated);

			// Feed consumption logic based on age and number of birds
			const feedConsumption = (DFI[age] * numBirds) / 1000;
			const roundedFeedConsumption = Math.round(feedConsumption);

			// Update result with feed consumption and weeks calculated
			setResult(
				`${newLang.NormalFeedIntake} = ${roundedFeedConsumption} ${lang.Kg} \n${weeksCalculated} ${newLang.weeks}`
			);
		} else {
			setResult(lang.InvalidInputMessage);
		}
	};

	useEnterKeyPress(calculateResults);

	return (
		<section
			className='container pt-150'
			dir={language === "ar" ? "rtl" : "ltr"}
		>
			<h2 style={{ marginBottom: "25px" }}>
				{
					newLang.brownPulletsManagmentPrograms
						.FeedConsumptionRateForBrownpulletsPrograms
				}
			</h2>
			<div className='row'>
				<div className='col-lg-6'>
					<InputField
						label={`${lang.NumberOfBirds}:`}
						value={numberOfBirds}
						onChange={(e) => setNumberOfBirds(e.target.value)}
						placeholder={lang.EnterNumberOfBirds}
					/>
					<InputField
						label={`${lang.AgeOfBirds}:`}
						value={ageOfBirds}
						onChange={(e) => setAgeOfBirds(e.target.value)}
						placeholder={lang.EnterAgeOfBirds}
					/>
					<div style={{ display: "flex", gap: "10px" }}>
						<button
							onClick={calculateResults}
							className='btn appointment-btn'
						>
							{lang.Calculate}
						</button>
					</div>
				</div>
				<div
					className='col-lg-6'
					style={{ display: "flex", justifyContent: "center" }}
				>
					<img
						src={icon}
						alt=''
						width={200}
						height={200}
					/>
				</div>
			</div>
			<textarea
				className='result'
				style={{ marginTop: "20px", width: "100%", height: "100px" }}
				value={result}
				readOnly
			/>
		</section>
	);
}

export default FeedConsumptionRateForBrownpulletsPrograms;
