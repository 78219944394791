import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Base URL without language parameter
const baseUrl = `https://www.vetlabclinic.com`;

// Create thunk with language parameter
export const drugInteractionsProgram = createAsyncThunk(
	"code/getCodesService",
	async ({ medicine1, medicine2, lang = "en" }, thunkAPI) => {
		try {
			// Construct URL with language parameter
			const url = `${baseUrl}/${lang}/medicine_code.php`;

			// Make request with language and other parameters
			const response = await axios.get(url, {
				params: {
					medicine1,
					medicine2,
				},
				responseType: "text", // Handle response as text if it's HTML
			});

			console.log(response.data); // Log response to see its content
			// Process response as needed
			return response.data;
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response ? e.response.data : e.message);
		}
	}
);
