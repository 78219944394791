import "./App.scss";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Services from "./pages/Services";
import BlogsPage from "./pages/BlogsPage";
import SingleBlog from "./pages/SingleBlog/SingleBlog";
import Contactus from "./pages/Contact/Contactus";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import Jobs from "./pages/Jobs";
import MyAccount from "./pages/MyAccount";
import SingleService from "./pages/SingleService/SingleService";

import BroilersManagment from "./pages/Services/BroilersManagment/BroilersManagment";
import BroilersManagmentProgramPage from "./pages/Services/BroilersManagment/ProgramPage";

import DrugInteractions from "./pages/Services/DrugInteractions/DrugInteractions";
import DrugInteractionsProgramPage from "./pages/Services/DrugInteractions/ProgramPage";

import LayersManagment from "./pages/Services/LayersManagment/LayersManagment";
import LayersManagmentProgramPage from "./pages/Services/LayersManagment/ProgramPage";

import FeedAnalysis from "./pages/Services/FeedAnalysis/FeedAnalysis";
import FeedAnalysisProgramPage from "./pages/Services/FeedAnalysis/ProgramPage";

import MainLayout from "./pages/MainLayout";
import Videos from "./pages/Videos";
import LightingRateProgram from "./pages/Services/BroilersManagment/LightingRateProgram/LightingRateProgram";
import EuropeanProductionEfficiencyFactorProgram from "./pages/Services/BroilersManagment/EuropeanProductionEfficiencyFactorProgram/EuropeanProductionEfficiencyFactorProgram";
import BroilersManagmentProgram from "./pages/Services/BroilersManagment/BroilersManagmentProgram/BroilersManagmentProgram";
import NormalMortalityRateProgram from "./pages/Services/BroilersManagment/NormalMortalityRateProgram/NormalMortalityRateProgram";
import NormalBodyWeightProgram from "./pages/Services/BroilersManagment/NormalBodyWeightProgram/NormalBodyWeightProgram";
import LivabilityPercentageProgram from "./pages/Services/BroilersManagment/LivabilityPercentageProgram/LivabilityPercentageProgram";
import FeedConsumptionRateProgram from "./pages/Services/BroilersManagment/FeedConsumptionRateProgram/FeedConsumptionRateProgram";
import AmountOfWaterNeededForVaccinationProgram from "./pages/Services/BroilersManagment/AmountOfWaterNeededForVaccinationProgram/AmountOfWaterNeededForVaccinationProgram";
import ProductionGuideProgram from "./pages/Services/BroilersManagment/ProductionGuideProgram/ProductionGuideProgram";
import TotalFeedConsumptionRateProgram from "./pages/Services/BroilersManagment/TotalFeedConsumptionRateProgram/TotalFeedConsumptionRateProgram";
import WaterConsumptionRateProgram from "./pages/Services/BroilersManagment/WaterConsumptionRateProgram/WaterConsumptionRateProgram";
import FeedConvertionRatioFCRProgram from "./pages/Services/BroilersManagment/FeedConvertionRatioFCRProgram/FeedConvertionRatioFCRProgram";
import DrugDosePrograms from "./pages/Services/DrugInteractions/DrugDosePrograms/DrugDosePrograms";
import DrugInteractionsProgram from "./pages/Services/DrugInteractions/DrugInteractionsProgram/DrugInteractionsProgram";
import PoultryFeedAnalysisProgram from "./pages/Services/FeedAnalysis/PoultryFeedAnalysisProgram/PoultryFeedAnalysisProgram";
import AnimalFeedAnalysisProgram from "./pages/Services/FeedAnalysis/AnimalFeedAnalysisProgram/AnimalFeedAnalysisProgram";
import RearingGuideforWhitePulletsProgram from "./pages/Services/LayersManagment/WhitePulletsManagmentPrograms/RearingGuideforWhitePulletsProgram/RearingGuideforWhitePulletsProgram";
import WaterConsumptionRateForWhitepulletsPrograms from "./pages/Services/LayersManagment/WhitePulletsManagmentPrograms/WaterConsumptionRateForWhitepulletsPrograms/WaterConsumptionRateForWhitepulletsPrograms";
import FeedConsumptionRateForWhitepulletsPrograms from "./pages/Services/LayersManagment/WhitePulletsManagmentPrograms/FeedConsumptionRateForWhitepulletsPrograms/FeedConsumptionRateForWhitepulletsPrograms";
import TotalFeedConsumptionForWhitePulletsProgram from "./pages/Services/LayersManagment/WhitePulletsManagmentPrograms/TotalFeedConsumptionForWhitePulletsProgram/TotalFeedConsumptionForWhitePulletsProgram";
import FeedConvertionRatioFCRForWhitePulletsProgram from "./pages/Services/LayersManagment/WhitePulletsManagmentPrograms/FeedConvertionRatioFCRForWhitePulletsProgram/FeedConvertionRatioFCRForWhitePulletsProgram";
import NormalBodyWeightForWhitePulletsProgram from "./pages/Services/LayersManagment/WhitePulletsManagmentPrograms/NormalBodyWeightForWhitePulletsProgram/NormalBodyWeightForWhitePulletsProgram";
import ProductionGuideForWhiteLayingHensProgram from "./pages/Services/LayersManagment/WhiteLayingHensManagmentPrograms/ProductionGuideForWhiteLayingHensProgram/ProductionGuideForWhiteLayingHensProgram";
import WaterConsumptionRateForWhiteLayingHensProgram from "./pages/Services/LayersManagment/WhiteLayingHensManagmentPrograms/WaterConsumptionRateForWhiteLayingHensProgram/WaterConsumptionRateForWhiteLayingHensProgram";
import FeedConsumptionRateForWhiteLayingHensProgram from "./pages/Services/LayersManagment/WhiteLayingHensManagmentPrograms/FeedConsumptionRateForWhiteLayingHensProgram/FeedConsumptionRateForWhiteLayingHensProgram";
import TotalFeedConsumptionForWhiteLayingHensProgram from "./pages/Services/LayersManagment/WhiteLayingHensManagmentPrograms/TotalFeedConsumptionForWhiteLayingHensProgram/TotalFeedConsumptionForWhiteLayingHensProgram";
import NormalBodyWeightForWhiteLayingHensProgram from "./pages/Services/LayersManagment/WhiteLayingHensManagmentPrograms/NormalBodyWeightForWhiteLayingHensProgram/NormalBodyWeightForWhiteLayingHensProgram";
import AverageEggWeightForWhiteLayingHensProgram from "./pages/Services/LayersManagment/WhiteLayingHensManagmentPrograms/AverageEggWeightForWhiteLayingHensProgram/AverageEggWeightForWhiteLayingHensProgram";
import EggPercentageForWhiteLayingHensProgram from "./pages/Services/LayersManagment/WhiteLayingHensManagmentPrograms/EggPercentageForWhiteLayingHensProgram/EggPercentageForWhiteLayingHensProgram";
import CumulativeEggProductionForWhiteLayingHensProgram from "./pages/Services/LayersManagment/WhiteLayingHensManagmentPrograms/CumulativeEggProductionForWhiteLayingHensProgram/CumulativeEggProductionForWhiteLayingHensProgram";
import RearingGuideforBrownPulletsProgram from "./pages/Services/LayersManagment/BrownPulletsManagmentPrograms/RearingGuideforBrownPulletsProgram/RearingGuideforBrownPulletsProgram";
import WaterConsumptionRateForBrownpulletsPrograms from "./pages/Services/LayersManagment/BrownPulletsManagmentPrograms/WaterConsumptionRateForBrownpulletsPrograms/WaterConsumptionRateForBrownpulletsPrograms";
import FeedConsumptionRateForBrownpulletsPrograms from "./pages/Services/LayersManagment/BrownPulletsManagmentPrograms/FeedConsumptionRateForBrownpulletsPrograms/FeedConsumptionRateForBrownpulletsPrograms";
import TotalFeedConsumptionForBrownPulletsProgram from "./pages/Services/LayersManagment/BrownPulletsManagmentPrograms/TotalFeedConsumptionForBrownPulletsProgram/TotalFeedConsumptionForBrownPulletsProgram";
import FeedConvertionRatioFCRForBrownPulletsProgram from "./pages/Services/LayersManagment/BrownPulletsManagmentPrograms/FeedConvertionRatioFCRForBrownPulletsProgram/FeedConvertionRatioFCRForBrownPulletsProgram";
import NormalBodyWeightForBrownPulletsProgram from "./pages/Services/LayersManagment/BrownPulletsManagmentPrograms/NormalBodyWeightForBrownPulletsProgram/NormalBodyWeightForBrownPulletsProgram";
import WaterConsumptionRateForBrownLayingHensProgram from "./pages/Services/LayersManagment/BrownLayingHensManagmentPrograms/WaterConsumptionRateForBrownLayingHensProgram/WaterConsumptionRateForBrownLayingHensProgram";
import ProductionGuideForBrownLayingHensProgram from "./pages/Services/LayersManagment/BrownLayingHensManagmentPrograms/ProductionGuideForBrownLayingHensProgram/ProductionGuideForBrownLayingHensProgram";
import FeedConsumptionRateForBrownLayingHensProgram from "./pages/Services/LayersManagment/BrownLayingHensManagmentPrograms/FeedConsumptionRateForBrownLayingHensProgram/FeedConsumptionRateForBrownLayingHensProgram";
import BrownFeedConsumptionRateForBrownLayingHensProgram from "./pages/Services/LayersManagment/BrownLayingHensManagmentPrograms/TotalFeedConsumptionForWhiteLayingHensProgramTwo/TotalFeedConsumptionForWhiteLayingHensProgramTwo";
import TotalFeedConsumptionForWhiteLayingHensProgramTwo from "./pages/Services/LayersManagment/BrownLayingHensManagmentPrograms/TotalFeedConsumptionForWhiteLayingHensProgramTwo/TotalFeedConsumptionForWhiteLayingHensProgramTwo";
import NormalBodyWeightForBrownLayingHensProgram from "./pages/Services/LayersManagment/BrownLayingHensManagmentPrograms/NormalBodyWeightForBrownLayingHensProgram/NormalBodyWeightForBrownLayingHensProgram";
import AverageEggWeightForBrownLayingHensProgram from "./pages/Services/LayersManagment/BrownLayingHensManagmentPrograms/AverageEggWeightForBrownLayingHensProgram/AverageEggWeightForBrownLayingHensProgram";
import EggPercentageForBrownLayingHensProgram from "./pages/Services/LayersManagment/BrownLayingHensManagmentPrograms/EggPercentageForBrownLayingHensProgram/EggPercentageForBrownLayingHensProgram";
import CumulativeEggProductionForBrownLayingHensProgram from "./pages/Services/LayersManagment/BrownLayingHensManagmentPrograms/CumulativeEggProductionForBrownLayingHensProgram/CumulativeEggProductionForBrownLayingHensProgram";
function App() {
	return (
		<Routes>
			<Route
				path='/'
				element={
					<MainLayout>
						<Home />
					</MainLayout>
				}
			/>
			<Route
				path='/services'
				element={
					<MainLayout>
						<Services />
					</MainLayout>
				}
			/>

			<Route
				path='/services/broilers-management'
				element={
					<MainLayout>
						<BroilersManagment />
					</MainLayout>
				}
			/>
			<Route
				path='/services/broilers-management/broilers-management'
				element={
					<MainLayout>
						{/* <BroilersManagment /> */}
						Not yet
					</MainLayout>
				}
			/>
			<Route
				path='/services/broilers-management/lighting-rate-program'
				element={
					<MainLayout>
						<LightingRateProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/broilers-management/european-production-efficiency-factor-program'
				element={
					<MainLayout>
						<EuropeanProductionEfficiencyFactorProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/broilers-management/normal-mortality-rate-program'
				element={
					<MainLayout>
						<NormalMortalityRateProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/broilers-management/normal-body-weight-program'
				element={
					<MainLayout>
						<NormalBodyWeightProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/broilers-management/livability-percentage-program'
				element={
					<MainLayout>
						<LivabilityPercentageProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/broilers-management/feed-convertion-ratio-FCR-program'
				element={
					<MainLayout>
						<FeedConvertionRatioFCRProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/broilers-management/feed-consumption-program'
				element={
					<MainLayout>
						<FeedConsumptionRateProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/broilers-management/amount-of-water-needed-for-vaccination-program'
				element={
					<MainLayout>
						<AmountOfWaterNeededForVaccinationProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/broilers-management/production-guide-program'
				element={
					<MainLayout>
						<ProductionGuideProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/broilers-management/total-feed-consumptionRate-program'
				element={
					<MainLayout>
						<TotalFeedConsumptionRateProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/broilers-management/water-consumption-rate-program'
				element={
					<MainLayout>
						<WaterConsumptionRateProgram />
					</MainLayout>
				}
			/>

			{/* ////////////////////////// */}
			{/* ////////////////////////// */}
			{/* ////////////////////////// */}
			{/* ////////////////////////// */}
			{/* Main  */}
			<Route
				path='/services/layers-management'
				element={
					<MainLayout>
						<LayersManagment />
					</MainLayout>
				}
			/>

			{/* One */}
			<Route
				path='/services/layers-management/rearing-guide-for-white-pullets-program'
				element={
					<MainLayout>
						<RearingGuideforWhitePulletsProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/water-consumption-rate-for-white-pullets-programs'
				element={
					<MainLayout>
						<WaterConsumptionRateForWhitepulletsPrograms />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/feed-consumption-rate-for-white-pullets-programs'
				element={
					<MainLayout>
						<FeedConsumptionRateForWhitepulletsPrograms />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/total-feed-consumption-for-white-pullets-program'
				element={
					<MainLayout>
						<TotalFeedConsumptionForWhitePulletsProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/feed-convertion-ratio-FCR-for-white-pullets-program'
				element={
					<MainLayout>
						<FeedConvertionRatioFCRForWhitePulletsProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/normal-body-weight-for-white-pullets-program'
				element={
					<MainLayout>
						<NormalBodyWeightForWhitePulletsProgram />
					</MainLayout>
				}
			/>

			{/* Two */}
			<Route
				path='/services/layers-management/production-guide-for-white-laying-hens-program'
				element={
					<MainLayout>
						<ProductionGuideForWhiteLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/water-consumption-rate-for-white-laying-hens-program'
				element={
					<MainLayout>
						<WaterConsumptionRateForWhiteLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/feed-consumption-rate-for-white-laying-hens-program'
				element={
					<MainLayout>
						<FeedConsumptionRateForWhiteLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/total-feed-consumption-for-white-laying-hens-program'
				element={
					<MainLayout>
						<TotalFeedConsumptionForWhiteLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/normal-body-weight-for-white-laying-hens-program'
				element={
					<MainLayout>
						<NormalBodyWeightForWhiteLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/average-egg-weight-for-white-laying-hens-program'
				element={
					<MainLayout>
						<AverageEggWeightForWhiteLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/egg-percent-age-for-white-laying-hens-program'
				element={
					<MainLayout>
						<EggPercentageForWhiteLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/cumulative-egg-production-for-white-laying-hens-program'
				element={
					<MainLayout>
						<CumulativeEggProductionForWhiteLayingHensProgram />
					</MainLayout>
				}
			/>

			{/* Three */}
			<Route
				path='/services/layers-management/rearing-guide-for-brown-pullets-program'
				element={
					<MainLayout>
						<RearingGuideforBrownPulletsProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/water-consumption-rate-for-brown-pullets-program'
				element={
					<MainLayout>
						<WaterConsumptionRateForBrownpulletsPrograms />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/feed-consumption-rate-for-brown-pullets-program'
				element={
					<MainLayout>
						<FeedConsumptionRateForBrownpulletsPrograms />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/total-feed-consumption-rate-for-brown-pullets-program'
				element={
					<MainLayout>
						<TotalFeedConsumptionForBrownPulletsProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/feed-convertion-ratio-FCR-for-brown-pullets-program'
				element={
					<MainLayout>
						<FeedConvertionRatioFCRForBrownPulletsProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/normal-body-weight-for-brown-pullets-program'
				element={
					<MainLayout>
						<NormalBodyWeightForBrownPulletsProgram />
					</MainLayout>
				}
			/>

			{/* Four */}
			<Route
				path='/services/layers-management/production-guide-for-brown-laying-hens-program'
				element={
					<MainLayout>
						<ProductionGuideForBrownLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/water-consumption-rate-for-brown-laying-hens-program'
				element={
					<MainLayout>
						<WaterConsumptionRateForBrownLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/feed-consumption-rate-for-brown-laying-hens-program'
				element={
					<MainLayout>
						<FeedConsumptionRateForBrownLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/total-feed-consumption-for-white-laying-hens-program-two'
				element={
					<MainLayout>
						<TotalFeedConsumptionForWhiteLayingHensProgramTwo />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/normal-body-weight-for-brown-laying-hens-program'
				element={
					<MainLayout>
						<NormalBodyWeightForBrownLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/average-egg-weight-for-brown-laying-hens-program'
				element={
					<MainLayout>
						<AverageEggWeightForBrownLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/egg-percent-age-for-brown-laying-hens-program'
				element={
					<MainLayout>
						<EggPercentageForBrownLayingHensProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/layers-management/cumulative-egg-production-for-brown-laying-hens-program'
				element={
					<MainLayout>
						<CumulativeEggProductionForBrownLayingHensProgram />
					</MainLayout>
				}
			/>

			{/* ////////////////////////// */}
			{/* ////////////////////////// */}
			{/* ////////////////////////// */}
			{/* ////////////////////////// */}
			<Route
				path='/services/drug-interactions/'
				element={
					<MainLayout>
						<DrugInteractions />
					</MainLayout>
				}
			/>
			<Route
				path='/services/drug-interactions/drug-interactions-program'
				element={
					<MainLayout>
						<DrugInteractionsProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/drug-interactions/drug-dose-programs'
				element={
					<MainLayout>
						<DrugDosePrograms />
					</MainLayout>
				}
			/>

			{/* ////////////////////////// */}
			{/* ////////////////////////// */}
			{/* ////////////////////////// */}
			{/* ////////////////////////// */}
			<Route
				path='/services/feed-analysis'
				element={
					<MainLayout>
						<FeedAnalysis />
					</MainLayout>
				}
			/>
			<Route
				path='/services/feed-analysis/poultry-feed-analysis-program'
				element={
					<MainLayout>
						<PoultryFeedAnalysisProgram />
					</MainLayout>
				}
			/>
			<Route
				path='/services/feed-analysis/animal-feed-analysis-program'
				element={
					<MainLayout>
						<AnimalFeedAnalysisProgram />
					</MainLayout>
				}
			/>
			{/* ////////////////////////// */}
			{/* ////////////////////////// */}
			{/* ////////////////////////// */}
			{/* ////////////////////////// */}
			{/* <Route path='/services/:url' element={<SingleService />} /> */}
			<Route
				path='/blogs'
				element={
					<MainLayout>
						<BlogsPage />
					</MainLayout>
				}
			/>
			<Route
				path='/blog/:url'
				element={
					<MainLayout>
						<SingleBlog />
					</MainLayout>
				}
			/>
			<Route
				path='/jobs'
				element={
					<MainLayout>
						<Jobs />
					</MainLayout>
				}
			/>
			<Route
				path='/videos'
				element={
					<MainLayout>
						<Videos />
					</MainLayout>
				}
			/>
			<Route
				path='/about'
				element={
					<MainLayout>
						<About />
					</MainLayout>
				}
			/>
			<Route
				path='/contact'
				element={
					<MainLayout>
						<Contactus />
					</MainLayout>
				}
			/>
			<Route
				path='/my-account'
				element={
					<MainLayout>
						<MyAccount />
					</MainLayout>
				}
			/>
			<Route
				path='/login'
				element={
					<MainLayout>
						<LoginPage />
					</MainLayout>
				}
			/>
			<Route
				path='/register'
				element={
					<MainLayout>
						<RegisterPage />
					</MainLayout>
				}
			/>
		</Routes>
	);
}

export default App;
